import { css } from 'styled-components'
import { COLORS } from './colors'

export const BASE = css`
  html {
    box-sizing: border-box;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    -webkit-backface-visibility: hidden;
  }

  body {
    font-family: 'Muli', Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-variant-ligatures: none;
    text-rendering: geometricPrecision;
    margin: 0;
    padding: 0;
    color: ${COLORS.gray};
    background-color: ${COLORS.background};
    overflow-y: scroll;
    min-height: 100vh;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }

  *:focus {
    outline: none;
  }

  img {
    max-width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .link-color {
    color: ${COLORS.primary};
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }
`
