import React, { useState, useEffect } from 'react'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import dayjs from 'dayjs'
import { Button, Header, Icon } from '@winnin/insights_ui'
import { useToastStore } from '../../../../stores/ToastStore'
import createSlug from '../../../../utils/createSlug'
import countryOptions from '../../../../consts/countries'
import Select from '../../../Select'
import {
  Action,
  ClearThumb,
  Content,
  Form,
  Image,
  Input,
  InputError,
  Label,
  SectionTitle,
  Title,
  UploadButton,
  UploadInput,
  UploadWrapper,
} from '../../styled'
import { CREATE_COMPANY_MUTATION, EDIT_COMPANY_MUTATION } from '../../query'
import getCountry from '../../../../utils/getCountry'
import { COLORS } from '../../../../theme/colors'
import StatusSwitch from '../../../StatusSwitch'
import UploadIcon from '../../../../assets/UploadIcon.svg'

const datePattern =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g
const messages = {
  saved: 'Company saved.',
  active: 'Company is Active as well as its Workspaces and Users.',
  inactive: 'Company is Inactive as well as its Workspaces and Users.',
}

export default function GeneralTab({
  company,
  onCreateOrEdit,
  onRequestClose,
  companyTypes,
  companySizes,
  isOpen,
}) {
  const { dispatch } = useToastStore()
  const client = useApolloClient()

  const [create] = useMutation(CREATE_COMPANY_MUTATION, {
    onCompleted({ createCompany }) {
      if (createCompany?.success) {
        onCreateOrEdit()
        onRequestClose()
        dispatch({ type: 'create', message: messages.saved })
      } else {
        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
      }
    },
    onError(error) {
      console.error('CompanyModal @ create >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    client,
  })

  const [edit] = useMutation(EDIT_COMPANY_MUTATION, {
    onCompleted({ editCompany }) {
      if (editCompany?.success) {
        const companyStatusMessage = companyForm?.status
          ? messages.active
          : messages.inactive
        const message =
          previousStatus !== companyForm.status
            ? companyStatusMessage
            : messages.saved
        onCreateOrEdit(company)
        onRequestClose()
        dispatch({
          type: 'create',
          message,
        })
      } else {
        const message =
          editCompany?.message || 'An error occurred. Please, try again.'
        dispatch({
          type: 'create',
          message,
        })
      }
    },
    onError(error) {
      console.error('CompanyModal @ edit >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    client,
  })

  const createCompany = (companyForm) => {
    const variables = {
      ...companyForm,
      slug: createSlug(companyForm.name),
    }

    delete variables.id
    delete variables.companyId

    create({ variables })
  }

  const editCompany = (companyForm) => {
    const variables = {
      ...companyForm,
      slug: createSlug(companyForm.name),
      companyId: companyForm.id,
    }

    delete variables.id
    previousStatus === companyForm.status && delete variables.status

    edit({ variables })
  }

  const companyInitalState = {
    companyId: null,
    companyTypeId: null,
    companySizeId: null,
    groupId: null,
    id: null,
    contractEmail: null,
    durationInMonths: 6,
    hubspotCompanyId: null,
    name: null,
    slug: null,
    thumbnail: null,
    status: true,
    brandExport: 10,
    creatorExport: 5,
    cultureExport: 5,
    performanceExport: 20,
    searchExport: 10,
    topicExport: 10,
    country: null,
    file: null,
  }

  const [companyForm, setCompanyForm] = useState(companyInitalState)
  const [previousStatus, setPreviousStatus] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)

  const requiredKeys = [
    'companyTypeId',
    'contractEmail',
    'name',
    'brandExport',
    'creatorExport',
    'cultureExport',
    'performanceExport',
    'searchExport',
    'topicExport',
    'country',
  ]

  const isSaveDisabled = !requiredKeys.every((k) => !!companyForm[k])

  useEffect(() => {
    if (company && isOpen) {
      setCompanyForm({
        companyId: company.companyId ?? null,
        companyTypeId: company.companyType.id ?? null,
        companySizeId: company?.size?.id ?? null,
        groupId: company.groupId ?? null,
        id: company.id ?? null,
        contractEmail: company.contractEmail ?? null,
        durationInMonths: company.durationInMonths ?? null,
        hubspotCompanyId: company.hubspotCompanyId ?? null,
        name: company.name ?? null,
        slug: company.slug ?? null,
        thumbnail: company.thumbnail ?? null,
        status: company.status ?? true,
        country: company.country ?? null,
        brandExport: company.brandExport ?? null,
        creatorExport: company.creatorExport ?? null,
        cultureExport: company.cultureExport ?? null,
        performanceExport: company.performanceExport ?? null,
        searchExport: company.searchExport ?? null,
        topicExport: company.topicExport ?? null,
        file: null,
      })
      setPreviousStatus(company.status ?? null)
    }
  }, [company, isOpen])

  useEffect(() => {
    if (!isOpen) {
      setCompanyForm(companyInitalState)
      setPreviewUrl(null)
      setPreviousStatus(null)
    }
  }, [isOpen])

  const handleStatusSwitch = (value) => {
    setCompanyForm({ ...companyForm, status: value })
  }

  const handleUpload = (e) => {
    const imageFile = e.target.files[0]
    setCompanyForm({ ...companyForm, file: imageFile })
    setPreviewUrl(URL.createObjectURL(imageFile))
  }

  const handleClear = (e) => {
    e.preventDefault()
    setCompanyForm({ ...companyForm, file: null })
    setPreviewUrl(null)
  }

  const companyTypeOptions = companyTypes?.companyTypes?.map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  )

  const currentCompanyType = companyForm.companyTypeId
    ? {
        label: companyTypes?.companyTypes?.find(
          ({ id }) => id === companyForm.companyTypeId
        )?.name,
        value: companyForm.companyTypeId,
      }
    : ''

  const companySizeOptions = companySizes?.listCompanySizes?.map(
    ({ id, name }) => ({
      value: id,
      label: name,
    })
  )

  const currentCompanySize = companyForm.companySizeId
    ? {
        label: companySizes?.listCompanySizes?.find(
          ({ id }) => id === companyForm.companySizeId
        )?.name,
        value: companyForm.companySizeId,
      }
    : ''

  return (
    <Content>
      <Title>Company</Title>

      <Form>
        <div>
          <Label required>Company Name</Label>
          <Input
            value={companyForm.name}
            onChange={(e) =>
              setCompanyForm({ ...companyForm, name: e.target.value })
            }
          />
        </div>

        <div />

        <div>
          <Label required>Company Type</Label>
          <Select
            value={currentCompanyType}
            onChange={({ value }) =>
              setCompanyForm({ ...companyForm, companyTypeId: value })
            }
            options={companyTypeOptions}
            placeholder="Select Type"
          />
        </div>

        <div>
          <Label>Company Size</Label>
          <Select
            value={currentCompanySize}
            onChange={({ value }) =>
              setCompanyForm({ ...companyForm, companySizeId: value })
            }
            options={companySizeOptions}
            placeholder="Select Size"
          />
        </div>

        <div>
          <Label required>Country</Label>
          <Select
            value={
              companyForm.country
                ? {
                    label: getCountry(companyForm.country),
                    value: companyForm.country,
                  }
                : ''
            }
            onChange={({ value }) =>
              setCompanyForm({ ...companyForm, country: value })
            }
            options={countryOptions}
            placeholder="Select Country"
          />
        </div>

        <div>
          <Label required>Contract Email</Label>
          <Input
            value={companyForm.contractEmail}
            onChange={(e) =>
              setCompanyForm({ ...companyForm, contractEmail: e.target.value })
            }
          />
        </div>

        <div>
          <Label>Hubspot Company Id</Label>
          <Input
            type="text"
            min="0"
            value={companyForm.hubspotCompanyId}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                hubspotCompanyId: e.target.value,
              })
            }
          />
        </div>

        <SectionTitle>Number of Exports</SectionTitle>

        <div>
          <Label required small>
            Culture Mapping Dashboard
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.cultureExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                cultureExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label required small>
            Topics & Keywords Intelligence
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.topicExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                topicExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label required small>
            Creators Profile Intelligence
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.creatorExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                creatorExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label required small>
            Brands Profile Intelligence
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.brandExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                brandExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label required small>
            Performance Compare
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.performanceExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                performanceExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label required small>
            Winnin Insights Search Database
          </Label>
          <Input
            type="number"
            min="0"
            value={companyForm.searchExport}
            onChange={(e) =>
              setCompanyForm({
                ...companyForm,
                searchExport: Number(e.target.value),
              })
            }
          />
        </div>

        <div>
          <Label>Status</Label>
          <StatusSwitch
            setActive={handleStatusSwitch}
            active={companyForm.status}
          />
        </div>

        <div>
          <Label>Add Thumbnail</Label>
          <UploadWrapper>
            <UploadInput
              id="upload-input"
              onChange={handleUpload}
              type="file"
              accept=".jpg,.jpeg,.png"
            />
            <UploadButton
              for="upload-input"
              className="Insights-ui__btn outline primary"
            >
              <img className="upload-icon" src={UploadIcon} alt="" /> Upload
              Image
            </UploadButton>

            <Image
              for="upload-input"
              thumbnail={previewUrl || companyForm.thumbnail}
            />

            <ClearThumb onClick={(e) => handleClear(e)} active={previewUrl}>
              <Icon icon="close" fill={COLORS.danger} />
            </ClearThumb>
          </UploadWrapper>
        </div>
      </Form>

      <Action>
        <div>
          {/* {!company && <Button appearance="outline">Add Another Company</Button>} */}
        </div>

        <div>
          <Button appearance="outline" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            disabled={isSaveDisabled}
            onClick={
              !company
                ? () => createCompany(companyForm)
                : () => editCompany(companyForm)
            }
          >
            Save
          </Button>
        </div>
      </Action>
    </Content>
  )
}
