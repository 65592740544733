import React, { useState, useEffect } from 'react'
import { Button } from '@winnin/insights_ui'
import { useMutation, useQuery } from '@apollo/react-hooks'
import dayjs from 'dayjs'

import Select from '../../../Select'
import {
  Action,
  Content,
  Form,
  Input,
  InputError,
  Label,
  Title,
  ChekboxInput,
} from '../../styled'
import {
  LIST_PLANS,
  CREATE_SIGNATURE,
  UPDATE_SIGNATURE,
  CANCEL_SIGNATURE,
  GET_CSTREATMENT,
} from './query'
import languageOptions from '../../../../consts/languages'
import StatusSwitch from '../../../StatusSwitch'
import getLanguage from '../../../../utils/getLanguage'
import { useToastStore } from '../../../../stores/ToastStore'
import findCsTreatment from '../../../../utils/getCSTreatmentOption'

const PLAN_DURATION = [
  {
    value: 72,
    label: 'Trial (72h)',
  },
  {
    value: 3,
    label: '3 months',
  },
  {
    value: 6,
    label: '6 months',
  },
  {
    value: 12,
    label: '12 months',
  },
  {
    value: 18,
    label: '18 months',
  },
  {
    value: 20,
    label: '20 months',
  },
  {
    value: 24,
    label: '24 months',
  },
]

export default function PlanTab({ onRequestClose, company }) {
  const [basePlan, setBasePlan] = useState(null)
  const [formState, setFormState] = useState(null)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const [csTreatmentOptions, setCsTreatmentOption] = useState(null)

  const { dispatch } = useToastStore()

  const { data, loading } = useQuery(LIST_PLANS)

  const { data: csTreatment, loading: isCsTreatmentLoading } =
    useQuery(GET_CSTREATMENT)

  useEffect(() => {
    if (csTreatment) {
      const formattedOptions = csTreatment?.listCsTreatmentTypes.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setCsTreatmentOption(formattedOptions)
    }
  }, [csTreatment])

  const [createSignature, { loading: loadingCreate }] = useMutation(
    CREATE_SIGNATURE,
    {
      onCompleted: ({ createCompanyPlan }) => {
        if (createCompanyPlan?.success) {
          onRequestClose()
          dispatch({
            type: 'create',
            message: 'The company plan has been updated.',
          })
          return
        }
        const message =
          createCompanyPlan?.message || 'An error occurred. Please, try again.'
        dispatch({
          type: 'create',
          message,
        })
      },
      onError: (error) => {
        console.error('PlanTab @ create >>>>>', error)
        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
      },
      refetchQueries: ['COMPANIES_QUERY'],
    }
  )

  const [updateSignature, { loading: loadingUpdate }] = useMutation(
    UPDATE_SIGNATURE,
    {
      onCompleted: ({ updateCompanyPlan }) => {
        if (updateCompanyPlan?.success) {
          onRequestClose()
          dispatch({
            type: 'create',
            message: 'The company plan has been updated.',
          })
          return
        }
        const message =
          updateCompanyPlan?.message || 'An error occurred. Please, try again.'
        dispatch({
          type: 'create',
          message,
        })
      },
      onError: (error) => {
        console.error('PlanTab @ updated >>>>>', error)
        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
      },
      refetchQueries: ['COMPANIES_QUERY'],
    }
  )

  const [cancelSignature, { loading: loadingCancel }] = useMutation(
    CANCEL_SIGNATURE,
    {
      onCompleted: ({ cancelCompanyPlan }) => {
        if (cancelCompanyPlan?.success) {
          onRequestClose()
          dispatch({
            type: 'create',
            message: 'The company plan has been updated.',
          })
          return
        }
        const message =
          cancelCompanyPlan?.message || 'An error occurred. Please, try again.'
        dispatch({
          type: 'create',
          message,
        })
      },
      onError: (error) => {
        console.error('PlanTab @ delete >>>>>', error)
        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
      },
      refetchQueries: ['COMPANIES_QUERY'],
    }
  )

  const activePlans =
    data?.listPlans?.entities?.length > 0
      ? data?.listPlans?.entities.map((plan) => ({
          label: plan.name,
          value: plan,
        }))
      : []

  const handlePlanSelect = (plan) => {
    setBasePlan(plan)
    setFormState({ ...plan.value, csTreatmentId: plan.value.csTreatment })
  }

  const handleChange = (e) => {
    const { name, value, type } = e.target
    if (type === 'number') {
      setFormState({ ...formState, [name]: +value })
      return
    }
    setFormState({ ...formState, [name]: value })
  }

  const handleReset = () => {
    setFormState(basePlan.value)
  }

  const isLegacyPlanSelected = basePlan?.label === 'Legacy'

  const handleSubmit = () => {
    const hasPlan = !!company?.plan?.id || !!company?.plan?.name

    if (isLegacyPlanSelected) {
      dispatch({
        type: 'create',
        message: 'Oops, you cannot edit the Legacy plan',
      })
      return
    }

    if (!company?.id) {
      dispatch({
        type: 'create',
        message: 'Oops, an error ocurred. No company selected.',
      })
      return
    }

    if (!formState?.duration) {
      dispatch({
        type: 'create',
        message: 'Oops, you need to select a plan duration.',
      })
      return
    }

    if (!basePlan) {
      dispatch({
        type: 'create',
        message: 'Please select a plan.',
      })
      return
    }

    const difference = Object.keys(basePlan.value).reduce(
      (previous, current) => {
        if (formState?.[current] !== basePlan.value?.[current]) {
          return { ...previous, [current]: formState?.[current] }
        }
        return previous
      },
      {}
    )

    const languagesWithAll = formState?.selectedLanguages.includes('all')
      ? formState?.selectedLanguages
      : [...formState?.selectedLanguages, 'all']

    const isTrial = formState?.duration?.value === 72
    const addMetric = isTrial ? 'hours' : 'months'

    const startDate = dayjs(formState.startDate).format('MM-DD-YYYY')
    const endDate = dayjs(formState.startDate)
      .add(formState.duration?.value, addMetric)
      .format('MM-DD-YYYY')

    const finalData = {
      ...difference,
      languages: languagesWithAll,
    }
    delete finalData?.csTreatment
    delete finalData?.isActive
    delete finalData?.duration

    if (hasPlan) {
      updateSignature({
        variables: {
          addons: finalData,
          planId: basePlan.value.id,
          companyId: company.id,
          startDate,
          endDate,
        },
      })
      return
    }

    createSignature({
      variables: {
        addons: finalData,
        planId: basePlan.value.id,
        companyId: company.id,
        startDate,
        endDate,
      },
    })
  }

  const handleCancelPlan = () => {
    if (!confirmCancel) {
      setConfirmCancel(true)
      return
    }
    if (!company?.id) {
      dispatch({
        type: 'create',
        message: 'Oops, an error ocurred. No company selected.',
      })
      return
    }
    cancelSignature({
      variables: {
        companyId: company.id,
      },
    })
  }

  const getDurationFromNumber = (number) => {
    const duration = PLAN_DURATION.find((item) => item.value === number)
    return duration
  }

  const handleProvider = (event) => {
    const { value } = event.target
    if (formState?.providers?.includes(value)) {
      const newProviders = formState?.providers?.filter(
        (item) => item !== value
      )
      setFormState({ ...formState, providers: newProviders })
      return
    }
    const newProviders = [...formState?.providers, value]
    setFormState({ ...formState, providers: newProviders })
  }

  useEffect(() => {
    if (company?.plan) {
      const plan = company?.plan
      delete plan.__typename
      const languages = company?.companyPlan?.languages?.filter(
        (language) => language !== 'all'
      )
      const dataFromCompanyPlan = Object.keys(company?.companyPlan).reduce(
        (prev, current) => {
          const baseValues = company?.companyPlan
          if (baseValues?.[current] || baseValues?.[current] === false) {
            return { ...prev, [current]: baseValues[current] }
          }
          return prev
        },
        {}
      )

      const variables = {
        ...plan,
        ...dataFromCompanyPlan,
        csTreatmentId: company.companyPlan.csTreatment || plan.csTreatment,
        selectedLanguages: languages,
        languages: languages?.length,
      }

      if (company.companyPlan.startDate) {
        const startDate = dayjs(company.companyPlan.startDate).add(1, 'day')
        const endDate = dayjs(company.companyPlan.endDate).add(1, 'day')
        const difference = dayjs(endDate).diff(startDate, 'month')

        variables.startDate = startDate.format('YYYY-MM-DD')
        variables.duration =
          difference > 1 ? getDurationFromNumber(difference) : PLAN_DURATION[0]
      }

      delete variables.endDate

      setFormState(variables)
      setBasePlan({ value: plan, label: plan.name })
    }
    return () => {
      setFormState(null)
      setBasePlan(null)
    }
  }, [company])

  const isLoading = loadingCreate || loadingUpdate || loadingCancel

  if (loading || isCsTreatmentLoading) return <div>...loading</div>

  const csTreatmentValue = findCsTreatment(
    formState?.csTreatmentId,
    csTreatmentOptions
  )

  const formLanguageOptions = languageOptions.filter(
    (item) => item.value !== 'all'
  )

  return (
    <Content>
      <Title>Plan</Title>

      <Form>
        <div>
          <Label required>Selected plan</Label>
          <Select
            value={basePlan}
            onChange={handlePlanSelect}
            options={activePlans}
            placeholder="Select Plan"
            isClearable
          />
        </div>
        <div>
          <Label>Is Active</Label>
          <StatusSwitch active={company?.companyPlan?.isActive} />
        </div>
        <div>
          <Label>Languages in plan</Label>
          <Input
            name="languages"
            value={formState?.languages}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>Languages</Label>
          <Select
            isMulti
            value={formState?.selectedLanguages
              ?.filter((language) => language !== 'all')
              ?.map((language) => ({
                label: getLanguage(language),
                value: language,
              }))}
            onChange={(selected) =>
              setFormState({
                ...formState,
                selectedLanguages: selected?.map((option) => option.value),
              })
            }
            options={formLanguageOptions}
            placeholder="Select Languages"
            isClearable
          />
          <InputError
            active={
              formState?.selectedLanguages?.length !==
              Number(formState?.languages)
            }
          >
            Incorrect amount of languages
          </InputError>
        </div>
        <div>
          <Label>CS treatment</Label>
          <Select
            value={csTreatmentValue}
            onChange={({ value }) =>
              setFormState({ ...formState, csTreatmentId: value })
            }
            options={csTreatmentOptions}
            placeholder="Select Type"
          />
        </div>
        <div>
          <Label>Has Winnin Reports</Label>
          <StatusSwitch
            setActive={(value) => {
              setFormState({ ...formState, hasWinninReports: value })
            }}
            active={formState?.hasWinninReports}
          />
        </div>
        <div>
          <Label>Plan start date</Label>
          <Input
            name="startDate"
            value={formState?.startDate}
            onChange={handleChange}
            type="date"
            required={!isLegacyPlanSelected}
            disabled={isLegacyPlanSelected}
          />
        </div>
        <div>
          <Label>Plan duration</Label>
          <Select
            value={formState?.duration}
            onChange={(option) =>
              setFormState({ ...formState, duration: option })
            }
            options={PLAN_DURATION}
            placeholder="Select Duration"
            isDisabled={isLegacyPlanSelected}
            required={!isLegacyPlanSelected}
          />
        </div>
        <div>
          <Label>N. of Editors</Label>
          <Input
            name="editors"
            value={formState?.editors}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>N. of viewers</Label>
          <Input
            name="viewers"
            value={formState?.viewers}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>N. of workspaces</Label>
          <Input
            name="workspaces"
            value={formState?.workspaces}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>N. of workspace channels</Label>
          <Input
            name="workspaceChannels"
            value={formState?.workspaceChannels}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>N. of jobs</Label>
          <Input
            name="jobs"
            value={formState?.jobs}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>N. of saved searches</Label>
          <Input
            name="savedPostSearches"
            value={formState?.savedPostSearches}
            onChange={handleChange}
            type="number"
            required
          />
        </div>
        <div>
          <Label>Providers</Label>
          <ChekboxInput>
            <label htmlFor="facebookProvider">
              Facebook
              <input
                type="checkbox"
                checked={formState?.providers?.includes('fb')}
                id="facebookProvider"
                value="fb"
                onChange={handleProvider}
              />
            </label>
          </ChekboxInput>
          <ChekboxInput>
            <label htmlFor="instagramProvider">
              Instagram
              <input
                type="checkbox"
                checked={formState?.providers?.includes('in')}
                id="instagramProvider"
                value="in"
                onChange={handleProvider}
              />
            </label>
          </ChekboxInput>
          <ChekboxInput>
            <label htmlFor="youtubeProvider">
              Youtube
              <input
                type="checkbox"
                checked={formState?.providers?.includes('yt')}
                id="youtubeProvider"
                value="yt"
                onChange={handleProvider}
              />
            </label>
          </ChekboxInput>
          <ChekboxInput>
            <label htmlFor="twitchProvider">
              Twitch
              <input
                type="checkbox"
                checked={formState?.providers?.includes('tw')}
                id="twitchProvider"
                value="tw"
                onChange={handleProvider}
              />
            </label>
          </ChekboxInput>
          <ChekboxInput>
            <label htmlFor="tiktokProvider">
              TikTok
              <input
                type="checkbox"
                checked={formState?.providers?.includes('tt')}
                id="tiktokProvider"
                value="tt"
                onChange={handleProvider}
              />
            </label>
          </ChekboxInput>
        </div>
      </Form>
      <Action>
        <div>
          <Button
            appearance="outline"
            onClick={handleReset}
            disabled={isLoading}
          >
            Reset plan
          </Button>
          <Button
            appearance="outline"
            onClick={handleCancelPlan}
            disabled={isLoading}
          >
            {confirmCancel
              ? 'Are you sure you want to cancel the plan?'
              : 'Cancel plan'}
          </Button>
        </div>
        <div>
          <Button
            appearance="outline"
            onClick={onRequestClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading}>
            Save
          </Button>
        </div>
      </Action>
    </Content>
  )
}
