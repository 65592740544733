import { gql } from 'apollo-boost'

export const ADD_VIDEOS = gql`
  mutation ($data: FetchPostSyncInput!) {
    fetchPostSync(data: $data) {
      ... on ScrapedPostBasic {
        id
        provider
        title
        thumbnail
      }
      ... on StatusMessage {
        code
        message
        success
      }
    }
  }
`

export const ADD_CREATORS = gql`
  mutation ($data: FetchCreatorSyncInput!) {
    fetchCreatorSync(data: $data) {
      ... on ScrapedCreatorBasic {
        id
        name: title
        provider
        thumbnail
      }
      ... on StatusMessage {
        code
        message
        success
        title
      }
    }
  }
`
