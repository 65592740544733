import { gql } from 'apollo-boost'

export const LIST_PLANS = gql`
  query listPlans {
    listPlans {
      entities {
        id
        name
        jobs
        hasWinninReports
        csTreatment
        editors
        viewers
        workspaces
        workspaceChannels
        languages
        isActive
        savedPostSearches
      }
      rows
    }
  }
`

export const CREATE_SIGNATURE = gql`
  mutation createCompanyPlan(
    $planId: ID!
    $companyId: String!
    $startDate: Date!
    $endDate: Date!
    $addons: PlanAddonsInput!
  ) {
    createCompanyPlan(
      planId: $planId
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      addons: $addons
    ) {
      success
      message
    }
  }
`

export const UPDATE_SIGNATURE = gql`
  mutation updateSignature(
    $planId: ID!
    $companyId: String!
    $startDate: Date!
    $endDate: Date!
    $addons: PlanAddonsInput!
  ) {
    updateCompanyPlan(
      planId: $planId
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      addons: $addons
    ) {
      success
      message
    }
  }
`

export const CANCEL_SIGNATURE = gql`
  mutation cancelSignature($companyId: String!) {
    cancelCompanyPlan(companyId: $companyId) {
      success
      message
    }
  }
`

export const GET_CSTREATMENT = gql`
  query GgetCsTreatment {
    listCsTreatmentTypes {
      id
      name
    }
  }
`
