import mixpanel from 'mixpanel-browser'
import getEnvFromLocation from './getEnvFromLocation'

const getMixpanelEnv = function () {
  const envUrl = getEnvFromLocation()
  switch (envUrl) {
    case 'production':
      return '0079fb1e0369119a39329b97d8258eb3'

    case 'staging':
      return '5c4262dacacb83893e0b256f9362d240'

    case 'local':
    case 'test':
    case 'development':
      return '0fc11f045ea31174e3b6070965f60130'

    default:
      throw new Error(`Invalid env name for mixpanel. ${envUrl}`)
  }
}

const MIXPANEL_TOKEN = getMixpanelEnv()

export function startMixpanel() {
  mixpanel.init(MIXPANEL_TOKEN, {
    api_host: 'https://api.mixpanel.com',
    persistence: 'localStorage',
  })
}

export function userTrack(user) {
  mixpanel.identify(user.id.toString())
  mixpanel.people.set({
    id: user.id,
    role: user.role,
  })
}
