import { ApolloClient, ApolloLink } from 'apollo-boost'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { createUploadLink } from 'apollo-upload-client'
import { keycloak } from './utils/auth'
import { REALMS } from './consts/keycloak'
import getEnvFromLocation from './utils/getEnvFromLocation'
import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const regExValidation = {
  jwt: /jwt/,
  UNAUTHORIZED: /UNAUTHORIZED/,
}

const httpLink = createUploadLink({
  uri: '/graphql',
  // uri: 'http://localhost:8081/graphql',
})

const env = getEnvFromLocation()

const authLink = setContext(async (_, { headers }) => {
  const accessToken = keycloak.token

  return {
    headers: {
      ...headers,
      ...(accessToken && { authorization: `${accessToken}` }),
      realm: REALMS[env],
    },
  }
})

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )

      if (
        regExValidation.jwt.test(message) ||
        regExValidation.UNAUTHORIZED.test(message)
      ) {
        keycloak.logout()
      }
    })
  }
  if (networkError) console.warn(`[Network error]: ${networkError}`)
})

const link = ApolloLink.from([
  createPersistedQueryLink({ useGETForHashedQueries: true }),
  authLink,
  ErrorLink,
  httpLink,
])

export default new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
})
