import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  color: ${COLORS.primary_dark1};
  font-size: 36px;
  font-weight: bold;
`

export const Text = styled.p`
  color: ${COLORS.gray_dark1};
  margin-top: 36px;
`
