import React from 'react'
import Modal from '../Modal'
import ModalTabs from '../ModalTabs'
import GeneralTab from './tabs/GeneralTab'
import PlanTab from './tabs/PlanTab'
import AssociatedBrandsTab from './tabs/AssociatedBrandsTab'

const CompanyModal = ({
  isOpen,
  onRequestClose,
  company,
  onCreateOrEdit,
  contractsData,
  companyTypes,
  companySizes,
}) => {
  const tabs = {
    company: (
      <GeneralTab
        company={company}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onCreateOrEdit={onCreateOrEdit}
        contractsData={contractsData}
        companyTypes={companyTypes}
        companySizes={companySizes}
      />
    ),
    plan: <PlanTab onRequestClose={onRequestClose} company={company} />,
    brands: (
      <AssociatedBrandsTab onRequestClose={onRequestClose} company={company} />
    ),
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTabs tabs={tabs} />
    </Modal>
  )
}

export default CompanyModal
