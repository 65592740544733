import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 35px;
  padding: 10px 10px 0;
`

export const TabButton = styled.button`
  position: relative;
  padding: 0px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  text-align: left;
  color: ${({ isActive }) => (isActive ? COLORS.primary : COLORS.gray_dark1)};
  ${({ isActive }) => {
    if (isActive) {
      return `&::after {
          border: 1px solid #6324c6;
          content: '';
          left: 1px;
          position: absolute;
          top: 19px;
          transition: width 0.2s;
          transition-delay: 0.2s;
          width: 32px;
        }`
    }
  }}
`
