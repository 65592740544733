import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Content = styled.div`
  padding: 20px 30px;
  min-height: 500px;
`

export const Title = styled.h1`
  color: ${COLORS.gray_dark2};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`

export const Subtitle = styled.h2`
  color: ${COLORS.gray_dark2};
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
`

export const BrandText = styled.h2`
  color: ${COLORS.gray_dark2};
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 12px;
`

export const Label = styled.label`
  display: block;
  color: ${COLORS.gray_dark2};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;

  ${({ required }) =>
    required &&
    `
  &::after {
    content: ' *';
    color: ${COLORS.primary};
  }
`}
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 10px;
  font-weight: 300;
  color: hsl(0, 0%, 20%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;

  &:hover {
    border: 1px solid ${COLORS.gray};
  }

  &:focus {
    border: 1px solid ${COLORS.primary};
  }
`

export const Form = styled.form`
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: repeat(2, 1fr);
`

export const Action = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0;
  }

  button:first-child {
    margin-right: 19px;
  }
`

export const WideInput = styled.div`
  grid-column: span 2;
`

export const UploadWrapper = styled.div`
  display: inline-flex;
`

export const UploadInput = styled.input`
  display: none;
`

export const UploadButton = styled.label`
  margin: 0;

  .upload-icon {
    margin-right: 6px;
  }
`
export const Image = styled.label`
  width: 38px;
  height: 38px;
  margin-left: 17px;
  border-radius: 50%;
  border: 0.5px solid ${COLORS.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ thumbnail }) =>
    thumbnail
      ? `
          background-image: url(${thumbnail});
          background-size: cover;
          background-position: center center;`
      : `
          background-color: ${COLORS.gray};

          &::after {
            content: "No image";
            border-radius: 50%;
            font-size: 10px;
            text-align: center;
            white-space: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            text-transform: uppercase;
            color: ${COLORS.base_color2};
          }
        `}
`

export const ClearThumb = styled.button`
  transition: 200ms;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? '1' : '0')};
`
