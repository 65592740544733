import { gql } from 'apollo-boost'

export const WORKSPACES_QUERY = gql`
  query WORKSPACES_QUERY(
    $limit: Int = 50
    $sort: SearchSort = { field: "name", order: "asc" }
    $offset: Int = 0
    $timestamp: Int
    $countries: [String!]! = []
    $companyIds: [String!]! = []
    $name: String
  ) {
    workspaces(
      limit: $limit
      sort: $sort
      offset: $offset
      timestamp: $timestamp
      countries: $countries
      companyIds: $companyIds
      name: $name
    ) {
      entities {
        ... on Brand {
          category {
            name
            country
          }
          brandSOA
          id
          name
          thumbnail
          companyId
          company {
            registeredUsers
            id
            createdAt
            name
            slug
            companyPlan {
              id: planId
              languages
            }
            associatedBrands {
              id
              name
              creators {
                name
                title
                provider
                creatorId
                thumbnail
              }
            }
          }
          panels {
            id
            title
            brandId
          }
          hasPanel
          registeredUsers
          status
          country
        }
      }
      rows
    }
  }
`

export const COMPANIES_QUERY = gql`
  query COMPANIES_QUERY {
    companies2 {
      entities {
        ... on Company {
          registeredUsers
          id
          createdAt
          name
          slug
          plan {
            id
            languages
          }
          companyPlan {
            id: planId
            planId
            languages
            startDate
            endDate
          }
        }
      }
      rows
    }
  }
`
