import { gql } from 'apollo-boost'

export const EDIT_BRAND_MUTATION = gql`
  mutation EDIT_BRAND_MUTATION(
    $id: String!
    $name: String!
    $thumbnail: String = ""
    $companyId: String!
    $hasPanel: Boolean! = true
    $country: String!
    $status: Boolean = true
    $file: Upload
  ) {
    editBrand(
      id: $id
      name: $name
      thumbnail: $thumbnail
      companyId: $companyId
      hasPanel: $hasPanel
      country: $country
      status: $status
      file: $file
    ) {
      success
      message
    }
  }
`

export const QUERY_COMPANIES = gql`
  query QUERY_COMPANIES {
    companies2 {
      entities {
        registeredUsers
        id
        createdAt
        name
        slug
        plan {
          id
          languages
        }
        companyPlan {
          id: planId
          planId
          languages
        }
      }
    }
  }
`

export const CREATE_WORSPACE = gql`
  mutation createWorkspace(
    $name: String!
    $companyId: String!
    $country: Country!
    $file: Upload
    $status: Boolean
  ) {
    createWorkspace(
      name: $name
      companyId: $companyId
      country: $country
      file: $file
      status: $status
    ) {
      success
      code
    }
  }
`
