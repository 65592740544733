import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Row = styled.tr`
  ${({ loading }) => {
    return loading
      ? `
      box-shadow: 0 0 0 1px ${COLORS.warning};
    `
      : ''
  }}
  height: 64px;
  grid-template-columns: ${({ columns }) => columns};
  background: ${COLORS.base_color2};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 5px;
  transition: box-shadow 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: ${COLORS.shadow_light};
  }

  ${({ empty }) =>
    empty &&
    `&:hover {
      box-shadow: none;
      cursor: default;
    }`}
`

export const Data = styled.td`
  font-weight: normal;
  font-size: 14px;
  color: ${COLORS.primary_dark2};
  padding: 0;
  position: relative;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const Status = styled(Data)`
  color: ${({ active }) => (active ? COLORS.primary : COLORS.danger)};
`

export const Role = styled(Data)`
  color: ${({ active }) => (active ? COLORS.base_color1 : COLORS.danger)};
`

export const WithLegend = styled(Data)`
  span {
    display: block;
    color: ${COLORS.gray};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
