export const REALMS = {
  local: 'sudo_dev',
  test: 'sudo_dev',
  development: 'sudo_dev',
  staging: 'sudo_prod',
  production: 'sudo_prod',
}

export const CLIENT_IDS = {
  local: 'sudo-test-env',
  test: 'sudo-test-env',
  development: 'sudo-dev',
  staging: 'sudo-prod',
  production: 'sudo-prod',
}

export const ENDPOINT = {
  local: 'https://login.dev.insights.winnin.com/auth/',
  test: 'https://login.dev.insights.winnin.com/auth/',
  development: 'https://login.dev.insights.winnin.com/auth/',
  staging: 'https://login-insights.winnin.com/auth/',
  production: 'https://login-insights.winnin.com/auth/',
}
