import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { keycloak } = useKeycloak()
  const isUserAuthenticated = keycloak.authenticated

  if (!isUserAuthenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default PrivateRoute
