import { gql } from 'apollo-boost'

export const CREATE_COMPANY_MUTATION = gql`
  mutation CREATE_COMPANY_MUTATION(
    $companyTypeId: Int
    $companySizeId: Int
    $name: String!
    $durationInMonths: Int!
    $contractEmail: String!
    $slug: String!
    $groupId: String = ""
    $thumbnail: String = ""
    $cultureExport: Int = 5
    $topicExport: Int = 10
    $creatorExport: Int = 20
    $brandExport: Int = 10
    $performanceExport: Int = 5
    $searchExport: Int = 10
    $country: String
    $status: Boolean
    $file: Upload
    $hubspotCompanyId: String
  ) {
    createCompany(
      contractStart: "01/01/2022"
      companyTypeId: $companyTypeId
      sizeId: $companySizeId
      name: $name
      durationInMonths: $durationInMonths
      contractEmail: $contractEmail
      slug: $slug
      groupId: $groupId
      thumbnail: $thumbnail
      cultureExport: $cultureExport
      topicExport: $topicExport
      creatorExport: $creatorExport
      brandExport: $brandExport
      performanceExport: $performanceExport
      searchExport: $searchExport
      country: $country
      status: $status
      file: $file
      hubspotCompanyId: $hubspotCompanyId
    ) {
      success
      message
    }
  }
`

export const EDIT_COMPANY_MUTATION = gql`
  mutation EDIT_COMPANY_MUTATION(
    $companyTypeId: Int
    $companySizeId: Int
    $name: String!
    $durationInMonths: Int!
    $contractEmail: String!
    $slug: String!
    $groupId: String = ""
    $thumbnail: String = ""
    $cultureExport: Int = 5
    $topicExport: Int = 10
    $creatorExport: Int = 20
    $brandExport: Int = 10
    $performanceExport: Int = 5
    $searchExport: Int = 10
    $country: String
    $status: Boolean
    $companyId: String!
    $file: Upload
    $hubspotCompanyId: String
  ) {
    editCompany(
      contractStart: "01/01/2022"
      companyTypeId: $companyTypeId
      sizeId: $companySizeId
      name: $name
      durationInMonths: $durationInMonths
      contractEmail: $contractEmail
      slug: $slug
      groupId: $groupId
      thumbnail: $thumbnail
      cultureExport: $cultureExport
      topicExport: $topicExport
      creatorExport: $creatorExport
      brandExport: $brandExport
      performanceExport: $performanceExport
      searchExport: $searchExport
      country: $country
      status: $status
      companyId: $companyId
      file: $file
      hubspotCompanyId: $hubspotCompanyId
    ) {
      success
      message
    }
  }
`

export const COMPANY_TYPES_QUERY = gql`
  query companyModal_companyTypesQuery {
    companyTypes {
      id
      name
    }
  }
`
