import { gql } from 'apollo-boost'

export const CREATE_COMPANY_ASSOCIATED_BRAND = gql`
  mutation CREATE_COMPANY_ASSOCIATED_BRAND(
    $companyId: ID!
    $brandName: String!
    $creatorURLs: [String!]
  ) {
    createCompanyAssociatedBrand(
      companyId: $companyId
      associatedBrand: { name: $brandName, creatorURLs: $creatorURLs }
    ) {
      id
      name
      creators {
        _id
        thumbnail
      }
    }
  }
`

export const DELETE_COMPANY_ASSOCIATED_BRAND = gql`
  mutation DELETE_COMPANY_ASSOCIATED_BRAND($id: ID!) {
    deleteCompanyAssociatedBrand(id: $id) {
      success
      code
    }
  }
`
