import React from 'react'
import { Icon } from '@winnin/insights_ui'
import {
  ArrowWrapper,
  Wrapper,
  TablePaginationGrid,
  Text,
  SmallSelect,
} from './styled'
import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTS } from '../../consts/pagination'
import { useToastStore } from '../../stores/ToastStore'
import { COLORS } from '../../theme/colors'

const TablePagination = ({
  count,
  rowsPerPage,
  page,
  leftSpacing,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const totalPages = Math.ceil(count / rowsPerPage.value) - 1
  const { dispatch } = useToastStore()
  const isFirstPage = totalPages ? page - 1 === -1 : true
  const isLastPage = totalPages ? page === totalPages : true

  const handleNextPage = () => {
    isLastPage
      ? dispatch({ type: 'create', message: "You're already in the last page" })
      : onChangePage(page + 1)
  }

  const handlePreviousPage = () => {
    isFirstPage
      ? dispatch({
          type: 'create',
          message: "You're already in the first page",
        })
      : onChangePage(page - 1)
  }

  const rowCount =
    count < rowsPerPage.value ? count : (page + 1) * rowsPerPage.value

  return (
    <Wrapper leftSpacing={leftSpacing}>
      <div />
      <TablePaginationGrid>
        <Text>Rows per page</Text>
        <SmallSelect
          value={rowsPerPage}
          style={{ width: '25px' }}
          onChange={(rowsPerPageObj) => {
            onChangeRowsPerPage(rowsPerPageObj)
          }}
          options={TABLE_PAGINATION_ROWS_PER_PAGE_OPTS}
        />
        <Text>
          {count < rowsPerPage.value ? '1' : rowsPerPage.value * page + 1}-
          {rowCount > count ? count : rowCount} of {count}
        </Text>
        <ArrowWrapper>
          <Icon
            onClick={() => !isFirstPage && onChangePage(0)}
            icon="arrowBackward"
            fill={isFirstPage ? COLORS.gray : COLORS.base_color1}
            width="16"
            height="16"
          />
          <Icon
            onClick={handlePreviousPage}
            icon="arrowLeft"
            fill={isFirstPage ? COLORS.gray : COLORS.base_color1}
            width="16"
            height="16"
          />
          <Icon
            onClick={handleNextPage}
            icon="arrowRight"
            fill={isLastPage ? COLORS.gray : COLORS.base_color1}
            width="16"
            height="16"
          />
          <Icon
            onClick={() => !isLastPage && onChangePage(totalPages)}
            icon="arrowForward"
            fill={isLastPage ? COLORS.gray : COLORS.base_color1}
            width="16"
            height="16"
          />
        </ArrowWrapper>
      </TablePaginationGrid>
    </Wrapper>
  )
}

export default TablePagination
