import React from 'react'
import { Wrapper, Switch, Label } from './styled'

const StatusSwitch = ({ setActive, active }) => (
  <Wrapper>
    <Label onClick={() => setActive(false)}>Inactive</Label>
    <Switch onClick={() => setActive(!active)} active={active} />
    <Label onClick={() => setActive(true)}>Active</Label>
  </Wrapper>
)

export default StatusSwitch
