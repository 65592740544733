import React from 'react'
import { Modal as UIModal } from '@winnin/insights_ui'

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    overflowY: 'overlay',
  },
  content: {
    top: '145px',
    left: '29.4%',
    right: '29.4%',
    bottom: 'auto',
    position: 'absolute',
    marginBottom: '145px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}

const Modal = ({ isOpen, onRequestClose, children, customStyles }) => {
  return (
    <UIModal
      customStyles={
        customStyles
          ? {
            overlay: { ...modalStyle.overlay, ...customStyles.overlay },
            content: { ...modalStyle.content, ...customStyles.content },
          }
          : modalStyle
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {children}
    </UIModal>
  )
}

export default Modal
