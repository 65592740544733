import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  transition: 200ms;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '0.8' : '0')};
  position: absolute;
  padding: 10px;
  margin: 0;
  background-color: ${COLORS.base_color1};
  color: ${COLORS.base_color2};
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translatex(-50%);
  border-radius: 4px;
  width: max-content;
  font-size: 14px;
  pointer-events: none;

  * {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }

  ul {
    list-style-type: none;
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    background-color: ${COLORS.base_color1};
    top: calc(100% - 3px);
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    transform-origin: center center;
  }
`
