export default function findCsTreatment(csTreatment, csTreatmentOptions) {
  if (!csTreatmentOptions) {
    return null
  }

  const value = csTreatmentOptions?.find((option) => {
    if (typeof csTreatment === 'number') {
      return option.value === csTreatment
    }
    return option?.label?.toLowerCase() === csTreatment?.toLowerCase()
  })

  return value
}
