import React from 'react'
import { LogoWrapper } from './styled'

export const Logo = () => (
  <LogoWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="89" height="24">
      <path d="M21.8 7.1H19c-.5 0-.7.4-.8.8l-1.7 11.6-2.3-9.2c-.3-1.3-.9-1.5-1.9-1.5h-2c-1 0-1.5.2-1.9 1.5l-2.3 9.2L4.4 7.8c0-.4-.3-.8-.8-.8H.9c-.5 0-.9.4-.9.9V8l2.6 13.2c.4 1.9.9 2.7 2.9 2.7h.8c1.6 0 2.6-.8 3-2.4l2.1-8.1 2.1 8.1c.4 1.7 1.4 2.4 3 2.4h.8c2 0 2.5-.8 2.8-2.6l2.6-13.2V8c0-.6-.4-.9-.9-.9zM27 5.2c.7 0 1.3-.2 1.8-.7.5-.4.7-1 .7-1.8s-.3-1.4-.8-1.9-1.1-.7-1.8-.7-1.3.2-1.8.7-.8 1.1-.8 1.9.3 1.4.8 1.9c.6.4 1.2.6 1.9.6zm1.4 2h-2.7c-.4 0-.7.4-.8.8v15.1c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8V8c0-.5-.3-.8-.8-.8zm18.3 7.3c0-1.1-.1-2.1-.3-3.1-.2-1-.6-1.8-1.2-2.5S43.9 7.7 43 7.3c-1-.3-2.1-.5-3.5-.5s-2.7.1-3.9.3c-.9.1-1.6.3-2.3.4h-.1c-.5.2-.7.5-.7.9v14.5c0 .4.3.8.8.8H36c.4 0 .7-.4.8-.8V11.1c0-.2.1-.4.3-.5 0 0 .4 0 .6-.1.5-.1 1-.1 1.4-.1.6 0 1.1.1 1.5.2.4.2.7.4.9.7s.4.8.5 1.4c.1.6.2 1.3.2 2.2v8c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8l.2-8.4zm17 0c0-1.1-.1-2.1-.3-3.1s-.6-1.8-1.2-2.5-1.3-1.2-2.2-1.6c-.9-.4-2.1-.6-3.4-.6-1.4 0-2.7.1-3.9.3-.9.1-1.6.3-2.3.4h-.1c-.5.2-.7.5-.7.9v14.5c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8V11c0-.2.1-.4.3-.5 0 0 .4 0 .6-.1.5-.1 1-.1 1.4-.1.6 0 1.1.1 1.5.2.4.2.7.4.9.7s.4.8.5 1.4c.1.6.2 1.3.2 2.2v8c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8l.1-8.3zm5.6-9.3c.7 0 1.3-.2 1.8-.7s.8-1.1.8-1.9-.3-1.4-.8-1.9S70 0 69.3 0s-1.3.2-1.8.7-.8 1.1-.8 1.9.3 1.4.8 1.9 1.1.7 1.8.7zm1.5 2h-2.7c-.4 0-.7.4-.8.8v15.1c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8V8c-.1-.5-.4-.8-.8-.8zM89 14.5c0-1.1-.1-2.1-.3-3.1-.2-1-.6-1.8-1.2-2.5s-1.3-1.2-2.2-1.6-2.1-.6-3.4-.6c-1.4 0-2.7.1-3.9.3-.9.1-1.6.3-2.3.4h-.1c-.5.2-.7.5-.7.9v14.5c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8V11c0-.2.1-.4.3-.5 0 0 .4 0 .6-.1.5-.1 1-.1 1.4-.1.6 0 1.1.1 1.5.2.4.2.7.4.9.7s.4.8.5 1.4c.1.6.2 1.3.2 2.2v8c0 .4.3.8.8.8h2.7c.4 0 .7-.4.8-.8l.1-8.3z" />
    </svg>

    <span className="pipe" />

    <span className="text">
      insights <small>beta</small>
    </span>
  </LogoWrapper>
)
