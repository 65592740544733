import React, { useState } from 'react'
import copy from 'clipboard-copy'
import { Button } from '@winnin/insights_ui'
import getCountry from '../../utils/getCountry'
import TableHeader from '../TableHeader'
import { Row, Data, Status, Image } from './styled'
import Tooltip from '../Tooltip'
import { useToastStore } from '../../stores/ToastStore'

const BoardsTable = ({ columns, headers, data, handleEdit, loading }) => {
  const [activeTooltip, setActiveTooltip] = useState(null)
  const { dispatch } = useToastStore()

  const isTooltipVisible = (id) => {
    return id === activeTooltip
  }

  return (
    <table>
      <TableHeader columns={columns} headers={headers} />

      {data.length ? (
        data.map((row) => (
          <Row
            columns={columns}
            key={row.id}
            onClick={() => handleEdit(row)}
            loading={loading}
          >
            <div>
              <Button
                appearance="outline"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copy(row.id)
                  dispatch({
                    type: 'create',
                    message: 'User ID copied to clipboard',
                  })
                }}
              >
                Copy ID
              </Button>
            </div>
            <Status active={row.status}>
              {row.status ? 'Active' : 'Inactive'}
            </Status>

            {row.name.length > 10 ? (
              <Image
                thumbnail={row.thumbnail}
                onMouseEnter={() => setActiveTooltip(`${row.id}-name`)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <div className="board-thumbnail" />
                <span>
                  {row.name.slice(0, 10)}...
                  <Tooltip visible={isTooltipVisible(`${row.id}-name`)}>
                    <p>{row.name}</p>
                  </Tooltip>
                </span>
              </Image>
            ) : (
              <Image thumbnail={row.thumbnail}>
                <div className="board-thumbnail" />
                <span>{row.name}</span>
              </Image>
            )}

            {row.company.name?.length > 10 ? (
              <Data
                onMouseEnter={() => setActiveTooltip(`${row.id}-company`)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                {`${row.company.name.slice(0, 10)}...`}
                <Tooltip visible={isTooltipVisible(`${row.id}-company`)}>
                  <p>{row.company.name}</p>
                </Tooltip>
              </Data>
            ) : (
              <Data>{row.company.name || '-'}</Data>
            )}

            {row.country && getCountry(row.country)?.length > 10 ? (
              <Data
                onMouseEnter={() => setActiveTooltip(`${row.id}-country`)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                {`${getCountry(row.country).slice(0, 10)}...`}
                <Tooltip visible={isTooltipVisible(`${row.id}-country`)}>
                  <p>{getCountry(row.country)}</p>
                </Tooltip>
              </Data>
            ) : (
              <Data>{row.country ? getCountry(row.country) : '-'}</Data>
            )}

            <Data>{row.registeredUsers ?? '-'}</Data>
          </Row>
        ))
      ) : (
        <Row empty />
      )}
    </table>
  )
}

export default BoardsTable
