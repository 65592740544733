import React, { useEffect, useState } from 'react'
import { Button, Icon, Loading } from '@winnin/insights_ui'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'

import {
  Action,
  ClearThumb,
  Content,
  Form,
  Image,
  Input,
  Label,
  Title,
  UploadButton,
  UploadInput,
  UploadWrapper,
} from '../../styled'
import { EDIT_BRAND_MUTATION, QUERY_COMPANIES, CREATE_WORSPACE } from './query'
import { COLORS } from '../../../../theme/colors'
import { useToastStore } from '../../../../stores/ToastStore'
import countryOptions from '../../../../consts/countries'
import getCountry from '../../../../utils/getCountry'
import Select from '../../../Select'
import sortByLabel from '../../../../utils/sortByLabel'
import StatusSwitch from '../../../StatusSwitch'
import UploadIcon from '../../../../assets/UploadIcon.svg'

const messages = {
  saved: 'Workspace saved.',
  active: 'Workspace is Active.',
  inactive: 'Workspace is Inactive.',
}

const BoardModal = ({ isOpen, onRequestClose, brand, onCreateOrEdit }) => {
  const { dispatch } = useToastStore()
  const client = useApolloClient()

  function getSuccessMessageFromStatus({ previousStatus, currentStatus }) {
    if (previousStatus !== currentStatus) {
      return currentStatus ? messages.active : messages.inactive
    }

    return messages.saved
  }

  const [createWorkspace] = useMutation(CREATE_WORSPACE, {
    onCompleted() {
      onCreateOrEdit()
      onRequestClose()

      dispatch({ type: 'create', message: messages.saved })
    },
    onError(error) {
      console.error('BoardModal @ create >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    client,
  })

  const [edit] = useMutation(EDIT_BRAND_MUTATION, {
    onCompleted() {
      const message = getSuccessMessageFromStatus({
        previousStatus,
        currentStatus: brandForm.status,
      })

      onCreateOrEdit(brand)
      onRequestClose()

      dispatch({
        type: 'create',
        message,
      })
    },
    onError(error) {
      console.error('BoardModal @ edit >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    client,
  })

  const createBrand = (brandFormData) => {
    const { id, companyName, thumbnail, hasPanel, ...variables } = brandFormData

    createWorkspace({ variables })
  }

  const editBrand = (brandForm) => {
    const variables = {
      ...brandForm,
    }

    delete variables.companyName

    edit({ variables })
  }

  const { loading, error, data } = useQuery(QUERY_COMPANIES, {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  })

  const companyOptions = data?.companies2?.entities
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort(sortByLabel)

  const brandInitalState = {
    id: null,
    name: null,
    thumbnail: '',
    companyId: null,
    companyName: null,
    hasPanel: true,
    country: null,
    status: true,
    file: null,
  }

  const [brandForm, setBrandForm] = useState(brandInitalState)
  const [previousStatus, setPreviousStatus] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const requiredKeys = ['companyId', 'name', 'country']
  const isSaveDisabled = !requiredKeys.every((k) => !!brandForm[k])

  useEffect(() => {
    if (brand && isOpen) {
      setBrandForm({
        id: brand.id ?? null,
        name: brand.name ?? null,
        thumbnail: brand.thumbnail ?? null,
        companyId: brand.companyId ?? null,
        companyName: brand.company.name ?? null,
        hasPanel: brand.hasPanel ?? true,
        country: brand.country ?? null,
        status: brand.status ?? true,
        file: null,
      })
      setPreviousStatus(brand.status ?? null)
    }
  }, [brand, isOpen])

  useEffect(() => {
    if (!isOpen) {
      setBrandForm(brandInitalState)
      setPreviewUrl(null)
      setPreviousStatus(null)
    }
  }, [isOpen])

  const handleStatusSwitch = (value) => {
    setBrandForm({ ...brandForm, status: value })
  }

  const handleUpload = (e) => {
    const imageFile = e.target.files[0]
    setBrandForm({ ...brandForm, file: imageFile })
    setPreviewUrl(URL.createObjectURL(imageFile))
  }

  const handleClear = (e) => {
    e.preventDefault()
    setBrandForm({ ...brandForm, file: null })
    setPreviewUrl(null)
  }

  return (
    <>
      {!error && !loading && (
        <Content>
          <Title>Panel</Title>

          <Form>
            <div>
              <Label required>Company</Label>
              <Select
                value={
                  brandForm?.companyId
                    ? {
                        label: brandForm?.companyName,
                        value: brandForm?.companyId,
                      }
                    : ''
                }
                placeholder="Select Company"
                options={companyOptions}
                onChange={(option) =>
                  setBrandForm({
                    ...brandForm,
                    companyName: option.label,
                    companyId: option.value,
                  })
                }
              />
            </div>

            <div>
              <Label required>Country</Label>
              <Select
                value={
                  brandForm.country
                    ? {
                        label: getCountry(brandForm.country),
                        value: brandForm.country,
                      }
                    : ''
                }
                onChange={({ value }) =>
                  setBrandForm({ ...brandForm, country: value })
                }
                options={countryOptions}
                placeholder="Select Country"
              />
            </div>

            <div>
              <Label required>Workspace Name</Label>
              <Input
                value={brandForm.name}
                onChange={(e) =>
                  setBrandForm({ ...brandForm, name: e.target.value })
                }
              />
            </div>

            <div className="empty-input-slot" />

            <div>
              <Label>Status</Label>
              <StatusSwitch
                setActive={handleStatusSwitch}
                active={brandForm.status}
              />
            </div>

            <div>
              <Label>Add Thumbnail</Label>
              <UploadWrapper>
                <UploadInput
                  id="upload-input"
                  onChange={handleUpload}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                />
                <UploadButton
                  for="upload-input"
                  className="Insights-ui__btn outline primary"
                >
                  <img className="upload-icon" src={UploadIcon} alt="" /> Upload
                  Image
                </UploadButton>

                <Image
                  for="upload-input"
                  thumbnail={previewUrl || brandForm.thumbnail}
                />

                <ClearThumb onClick={(e) => handleClear(e)} active={previewUrl}>
                  <Icon icon="close" fill={COLORS.danger} />
                </ClearThumb>
              </UploadWrapper>
            </div>
          </Form>

          <Action>
            <div>
              {/* {!brand && <Button appearance="outline">Add Another Brand</Button>} */}
            </div>

            <div>
              <Button appearance="outline" onClick={onRequestClose}>
                Cancel
              </Button>
              <Button
                disabled={isSaveDisabled}
                onClick={
                  !brand
                    ? () => createBrand(brandForm)
                    : () => editBrand(brandForm)
                }
              >
                Save
              </Button>
            </div>
          </Action>
        </Content>
      )}

      {error && <p>API error</p>}

      {loading && <Loading />}
    </>
  )
}

export default BoardModal
