import styled from 'styled-components'
import { Button } from '@winnin/insights_ui'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: ${COLORS.base_color1};
`

export const Action = styled(Button)`
  margin: 0;
  transition: background-color 200ms;
`
