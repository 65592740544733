export default [
  {
    label: 'Portuguese',
    value: 'por',
  },
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Spanish',
    value: 'spa',
  },
  {
    label: 'French',
    value: 'fre',
  },
  {
    label: 'German',
    value: 'ger',
  },
  {
    label: 'All',
    value: 'all',
  },
]
