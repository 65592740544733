const getEnvFromLocation = function () {
  const locationUrl = window?.location?.href

  if (locationUrl) {
    if (locationUrl.includes('http://localhost:3000/')) {
      return 'local'
    }
    if (locationUrl.includes('https://dev.sudo.insights.winnin.com')) {
      return 'development'
    }
    if (
      locationUrl.includes('https://sudo.insights.winnin.com') ||
      locationUrl.includes('https://staging.sudo.insights.winnin.com') ||
      locationUrl.includes('https://sudo-insights.winnin.com')
    ) {
      return 'production'
    }
    return 'test'
  }
}

export default getEnvFromLocation
