import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Content = styled.div`
  min-height: 360px;
  padding: 20px 30px;
`

export const Title = styled.h1`
  color: ${COLORS.gray_dark2};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`

export const Label = styled.label`
  display: block;
  color: ${COLORS.gray_dark2};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;

  &::after {
    content: ' *';
    color: ${COLORS.primary};
    opacity: ${({ required }) => (required ? 1 : 0)};
    transition: 200ms;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 10px;
  font-weight: 300;
  color: hsl(0, 0%, 20%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;

  &:hover {
    border: 1px solid ${COLORS.gray};
  }

  &:focus {
    border: 1px solid ${COLORS.primary};
  }
`

export const InputError = styled.p`
  position: absolute;
  color: ${COLORS.danger};
  font-size: 12px;
  font-weight: bold;
  transition: 200ms;
  opacity: ${({ active }) => (active ? '1' : '0')};
`

export const Form = styled.form`
  display: grid;
  grid-gap: 20px 30px;
  grid-template-columns: repeat(2, 1fr);
`

export const ResendEmailTitleWrapper = styled.div`
  margin-top: 32px;
`

export const ResendEmailButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    width: 206px;
  }
`

export const Action = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0;
  }

  button:first-child {
    margin-right: 19px;
  }
`

export const ApiError = styled.div`
  padding: 30px;
`
