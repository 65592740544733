export const PAGINATION = 25

export const TABLE_PAGINATION_ROWS_PER_PAGE_OPTS = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

export default PAGINATION
