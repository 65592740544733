import React, { useState, useEffect } from 'react'
import { Button, Loading } from '@winnin/insights_ui'
import {
  useMutation,
  useLazyQuery,
  useApolloClient,
  useQuery,
} from '@apollo/react-hooks'
import Modal from '../Modal'
import {
  Content,
  Title,
  Form,
  Label,
  Input,
  InputError,
  Action,
  ApiError,
  ResendEmailTitleWrapper,
  ResendEmailButtonWrapper,
} from './styled'
import Select from '../Select'
import {
  CREATE_USER_MUTATION,
  EDIT_USER_MUTATION,
  QUERY_BRANDS_COMPANIES,
  JOB_TITLES_QUERY,
  SEND_WELCOME_EMAIL,
  QUERY_COMPANIES,
} from './query'
import sortByLabel from '../../utils/sortByLabel'
import countryOptions from '../../consts/countries'
import getCountry from '../../utils/getCountry'
import roleOptions from '../../consts/roles'
import getRole from '../../utils/getRole'
import { useToastStore } from '../../stores/ToastStore'
import { userTrack } from '../../utils/mixpanel'

const messages = {
  saved: 'User saved.',
  active: 'User is Active.',
  inactive: 'User is Inactive.',
}

const UserModal = ({ isOpen, onRequestClose, user }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { dispatch } = useToastStore()
  const client = useApolloClient()

  const { data: companiesData } = useQuery(QUERY_COMPANIES)

  const [create] = useMutation(CREATE_USER_MUTATION, {
    onCompleted({ createUser }) {
      if (createUser.success) {
        setAlreadyExists(false)
        onRequestClose()
        dispatch({ type: 'create', message: messages.saved })
      } else {
        console.error(
          'Error creating user @CREATE_USER_MUTATION >>>>>>>>',
          createUser.message
        )
        createUser.message === 'User already exists'
          ? setAlreadyExists(true)
          : setApiError(true)
        setErrorMessage(createUser.message)
        dispatch({ type: 'create', message: createUser.message })
      }
    },
    client,
    refetchQueries: ['USERS_QUERY'],
    onError: (error) => {
      console.error('Error creating user @CREATE_USER_MUTATION >>>>>>>>', error)
      dispatch({
        type: 'create',
        message: error.message,
      })
    },
  })

  const [edit] = useMutation(EDIT_USER_MUTATION, {
    onCompleted({ editUser }) {
      if (editUser.success) {
        const getMsg = () => {
          if (previousRole === userForm.role) {
            return userForm.role ? messages.active : messages.inactive
          }
          return messages.save
        }
        const message = getMsg()

        setAlreadyExists(false)
        onRequestClose()
        dispatch({
          type: 'create',
          message,
        })

        userTrack(userForm)
      } else {
        editUser.message === 'User already exists'
          ? setAlreadyExists(true)
          : setApiError(true)
      }
    },
    client,
    refetchQueries: ['USERS_QUERY'],
  })

  const [
    queryBrandsCompanies,
    {
      data: brandsCompaniesData,
      loading: brandsCompaniesLoading,
      error: brandsCompaniesError,
    },
  ] = useMutation(QUERY_BRANDS_COMPANIES)

  const [sendWelcomeEmail, { loading: loadingSendEmail }] = useLazyQuery(
    SEND_WELCOME_EMAIL,
    {
      fetchPolicy: 'network-only',
      client,
      onCompleted: (response) => {
        !response.sendWelcomeEmail.success
          ? dispatch({
              type: 'create',
              message:
                "Error sending email, please make sure that the user isn't already activated",
            })
          : dispatch({
              type: 'create',
              message: 'Email sent',
            })
      },
    }
  )

  const [
    loadJobtitles,
    { data: jobTitlesData, loading: jobTitlesLoading, error: jobTitlesError },
  ] = useLazyQuery(JOB_TITLES_QUERY, {
    fetchPolicy: 'network-only',
    client,
  })

  const createUser = (userForm) => {
    const variables = {
      ...userForm,
      name: `${userForm.firstName} ${userForm.lastName}`,
      role: userForm?.role ?? 100,
    }

    delete variables.id
    delete variables.brands
    delete variables.company

    create({ variables })
  }

  const editUser = (userForm) => {
    const variables = {
      ...userForm,
      name: `${userForm.firstName} ${userForm.lastName}`,
      userId: user.id,
    }

    const isCompanyFull = isCurrentRoleAvailable()
    const isNewRoleViwerOrInactive =
      variables.role === 0 || variables.role === 50
    const isPayingRole = user.role >= 100
    const isChangingRole = user.role !== variables.role

    /* 
      Block role change from a non paying role
      to a paying role on a full company
    */
    if (
      isCompanyFull &&
      !isPayingRole &&
      !isNewRoleViwerOrInactive &&
      isChangingRole
    ) {
      dispatch({
        type: 'create',
        message:
          'The user company is full, you can only add/edit inactive or viewers role.',
      })
    } else {
      delete variables.id
      delete variables.brands
      delete variables.company
      edit({ variables })
    }
  }

  const brandsOptions = brandsCompaniesData?.brands
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .sort(sortByLabel)

  const userInitalState = {
    id: null,
    companyId: null,
    country: '',
    name: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    jobTitleId: null,
    brands: [],
    brandIds: [],
    role: null,
    status: true,
  }

  const [userForm, setUserForm] = useState(userInitalState)
  const [previousRole, setPreviousRole] = useState(null)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isBoardRequired, setIsBoardRequired] = useState(false)
  const [alreadyExists, setAlreadyExists] = useState(false)
  const [apiError, setApiError] = useState(false)

  const getSelectedCompany = () => {
    const selectedCompany = companiesData?.companies2?.entities?.find(
      (item) => item?.companyId === userForm?.company?.companyId
    )
    return selectedCompany
  }

  const isCurrentRoleAvailable = () => {
    const selectedCompany = getSelectedCompany()

    if (userForm?.role === 50) {
      const availableViewers =
        selectedCompany?.companyPlan?.viewers ?? selectedCompany?.plan?.viewers
      const currentViewers = selectedCompany?.usersCount?.viewer
      return availableViewers < currentViewers
    }

    if (userForm?.role > 50) {
      const availableEditors =
        selectedCompany?.companyPlan?.editors ?? selectedCompany?.plan?.editors
      const currentEditors = selectedCompany?.usersCount?.editor
      return availableEditors < currentEditors
    }

    return true
  }

  const companyOptions = companiesData?.companies2?.entities
    .map(({ name, companyId, companyType }) => ({
      value: companyId,
      label: name,
      disabled: userForm.role === 50 ? false : isCurrentRoleAvailable(),
      companyType,
    }))
    .sort(sortByLabel)

  useEffect(() => {
    queryBrandsCompanies()

    if (user && isOpen) {
      setUserForm({
        id: user.id ?? null,
        companyId: user.company.companyId ?? null,
        company: user.company ?? null,
        country: user.country ?? '',
        name: user.name ?? null,
        firstName: user.firstName ?? null,
        lastName: user.lastName ?? null,
        email: user.email ?? null,
        phone: user.phone ?? null,
        jobTitleId: user?.jobTitleType?.id ?? '',
        brands: user.brands ?? [],
        brandIds: user?.brands?.map((brand) => brand.id) || [],
        role: user.role ?? null,
        status: user.status,
      })
      setPreviousRole(user.role ?? null)
    }
  }, [user, isOpen])

  useEffect(() => {
    const requiredKeys = [
      'companyId',
      'lastName',
      'firstName',
      'lastName',
      'email',
      'jobTitleId',
      'country',
    ]
    const isViewerRole = userForm.role === 50

    if (isViewerRole) {
      setIsSaveDisabled(
        ![...requiredKeys, 'brands'].every((k) => {
          if (Array.isArray(userForm[k])) return !!userForm[k].length
          return !!userForm[k]
        })
      )
    } else {
      setIsSaveDisabled(!requiredKeys.every((k) => !!userForm[k]))
    }

    setIsBoardRequired(isViewerRole)
  }, [userForm])

  useEffect(() => {
    if (!isOpen) {
      setUserForm(userInitalState)
      setAlreadyExists(false)
      setApiError(false)
      setPreviousRole(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (userForm?.company?.companyType?.id) {
      const companyTypeId = userForm.company.companyType.id

      loadJobtitles({ variables: { companyTypeId } })
    }
  }, [userForm.company])

  const jobTitleOptions = jobTitlesData?.jobTitles?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const currentJobTitle = userForm.jobTitleId
    ? {
        label: jobTitlesData?.jobTitles?.find(
          ({ id }) => id === userForm.jobTitleId
        )?.name,
        value: userForm.jobTitleId,
      }
    : ''

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {!apiError &&
        !brandsCompaniesError &&
        !brandsCompaniesLoading &&
        !jobTitlesError && (
          <Content>
            <Title>Users</Title>

            <Form>
              <div>
                <Label>Role</Label>
                <Select
                  placeholder="Select Role"
                  value={
                    userForm?.role !== null
                      ? {
                          label: getRole(userForm?.role),
                          value: userForm?.role,
                        }
                      : ''
                  }
                  options={roleOptions}
                  onChange={({ value }) => {
                    setUserForm({ ...userForm, role: value })
                  }}
                />
              </div>

              <div>
                <Label required>Company</Label>
                <Select
                  value={
                    userForm?.company?.companyId
                      ? {
                          label: userForm?.company?.name,
                          value: userForm?.company?.companyId,
                        }
                      : ''
                  }
                  placeholder="Select Company"
                  options={companyOptions}
                  isDisabled={!userForm.role && userForm.role !== 0}
                  isOptionDisabled={(option) => option.disabled}
                  onChange={(option) => {
                    setUserForm({
                      ...userForm,
                      companyId: option.value,
                      company: {
                        name: option.label,
                        companyId: option.value,
                        companyType: option.companyType,
                      },
                      jobTitleId: null,
                    })
                  }}
                />
              </div>

              <div>
                <Label required>Country</Label>
                <Select
                  value={
                    userForm.country
                      ? {
                          label: getCountry(userForm.country),
                          value: userForm.country,
                        }
                      : ''
                  }
                  onChange={({ value }) =>
                    setUserForm({ ...userForm, country: value })
                  }
                  options={countryOptions}
                  placeholder="Select Country"
                />
              </div>

              <div>
                <Label required>First Name</Label>
                <Input
                  value={userForm.firstName}
                  onChange={(e) =>
                    setUserForm({ ...userForm, firstName: e.target.value })
                  }
                />
              </div>

              <div>
                <Label required>Last Name</Label>
                <Input
                  value={userForm.lastName}
                  onChange={(e) =>
                    setUserForm({ ...userForm, lastName: e.target.value })
                  }
                />
              </div>

              <div>
                <Label required>Email</Label>
                <Input
                  value={userForm.email}
                  onChange={(e) =>
                    setUserForm({ ...userForm, email: e.target.value })
                  }
                />
                <InputError active={alreadyExists}>
                  User already exists
                </InputError>
              </div>

              <div>
                <Label>Tel/Cel</Label>
                <Input
                  value={userForm.phone}
                  onChange={(e) =>
                    setUserForm({ ...userForm, phone: e.target.value })
                  }
                />
              </div>

              <div>
                <Label required>Job Title</Label>
                <Select
                  value={currentJobTitle}
                  onChange={({ value }) =>
                    setUserForm({ ...userForm, jobTitleId: value })
                  }
                  options={jobTitleOptions}
                  placeholder="Select Title"
                  isLoading={jobTitlesLoading}
                />
              </div>

              <div>
                <Label required={isBoardRequired}>Panels</Label>
                <Select
                  value={userForm.brands?.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  isMulti
                  options={brandsOptions}
                  placeholder="Select Brand"
                  onChange={(selected) =>
                    setUserForm({
                      ...userForm,
                      brandIds: selected?.map((option) => option.value) || [],
                      brands:
                        selected?.map((option) => ({
                          id: option.value,
                          name: option.label,
                        })) || [],
                    })
                  }
                />
              </div>
            </Form>
            <ResendEmailTitleWrapper>
              <Label>Email Resend</Label>
            </ResendEmailTitleWrapper>
            <ResendEmailButtonWrapper>
              <p>Here you can resend an email welcome to this user</p>
              {loadingSendEmail ? (
                <Loading />
              ) : (
                <Button
                  appearance="outline"
                  onClick={() =>
                    sendWelcomeEmail({ variables: { userId: user.id } })
                  }
                >
                  Resend Email
                </Button>
              )}
            </ResendEmailButtonWrapper>
            <Action>
              <div>
                <Button appearance="outline" onClick={onRequestClose}>
                  Cancel
                </Button>
                <Button
                  disabled={isSaveDisabled}
                  onClick={
                    !user
                      ? () => createUser(userForm)
                      : () => editUser(userForm)
                  }
                >
                  Save
                </Button>
              </div>
            </Action>
          </Content>
        )}

      {(apiError || brandsCompaniesError || jobTitlesError) && (
        <ApiError>
          <Title>Oops, something went wrong!</Title>
          <Label>
            {errorMessage || 'Please refresh the page or try again later.'}
          </Label>
        </ApiError>
      )}

      {brandsCompaniesLoading && <Loading />}
    </Modal>
  )
}

export default UserModal
