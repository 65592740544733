import React, { useState, useEffect } from 'react'
import { Wrapper } from './styled'
import { useToastStore } from '../../stores/ToastStore'
import { Icon } from '@winnin/insights_ui'
import { COLORS } from '../../theme/colors'

const Toast = () => {
  const { state } = useToastStore()
  const [isVisible, setIsVisible] = useState(false)

  const closeToast = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    if (!isVisible && !!state.message) {
      setIsVisible(true)

      setTimeout(() => {
        setIsVisible(false)
      }, 5000)
    }
  }, [state])

  return (
    <Wrapper visible={isVisible && state.message}>
      <p>{state.message}</p>
      <Icon onClick={closeToast} icon="close" fill={COLORS.base_color2} />
    </Wrapper>
  )
}

export default Toast
