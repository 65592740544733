import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button } from '@winnin/insights_ui'
import {
  Action,
  BrandText,
  Content,
  Form,
  Label,
  Subtitle,
  Title,
} from '../../styled'
import { GET_BRAND_OPTIONS, SET_BRAND_OPTION } from './query'
import Select from '../../../Select'
import { useToastStore } from '../../../../stores/ToastStore'

export default function WorkspaceBrand({ company, onRequestClose }) {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedBrand, setSelectedBrand] = useState(null)
  const { dispatch } = useToastStore()

  const { data, loading } = useQuery(GET_BRAND_OPTIONS)
  const [setBrandOption] = useMutation(SET_BRAND_OPTION, {
    onCompleted: () => {
      onRequestClose()

      dispatch({ type: 'create', message: 'Brand configurada com sucesso' })
    },
    onError: (error) => {
      console.error('WorkspaceBrand @ set >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
  })

  const availableCategories = useMemo(() => {
    const brandsCategories = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    if (!brandsCategories.length) {
      return []
    }
    const dedupedCategories = brandsCategories.reduce((prev, curr) => {
      const category = curr?.category?.name
      if (prev.includes(category)) {
        return prev
      }
      return [...prev, category]
    }, [])
    const formattedOptions = dedupedCategories.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
    return formattedOptions
  }, [data])

  const availableBrands = useMemo(() => {
    if (!selectedCategory) {
      return []
    }

    const brandsData = data?.listBrandSOAOnCategoryPaginated?.data ?? []
    const brandsWithCategory = brandsData.reduce((prev, curr) => {
      const currentCategoryName = curr?.category?.name
      const selectedCategoryName = selectedCategory?.value
      if (currentCategoryName === selectedCategoryName) {
        return [...prev, curr]
      }
      return prev
    }, [])

    const formattedOptions = brandsWithCategory.map((item) => {
      return {
        label: item?.brand?.name,
        value: item?.id,
      }
    })

    return formattedOptions
  }, [selectedCategory, data])

  const submitBrand = () => {
    const workspaceId = company?.panels?.[0]?.id
    if (!selectedBrand?.value || !workspaceId) {
      return
    }
    setBrandOption({
      variables: {
        input: {
          brandSOAOnCategoryId: selectedBrand?.value,
          workspaceId,
        },
      },
    })
  }

  const currentCategory = `${company?.brandSOA}, ${company?.category?.name} in ${company?.category?.country}`

  return (
    <Content>
      <Title>Brand para Share de Atenção</Title>
      <div>
        <Subtitle>Brand e categoria atual:</Subtitle>
        <BrandText>{company?.brandSOA ? currentCategory : '--'}</BrandText>
      </div>
      <div className="empty-input-slot" />
      <Form>
        <div>
          <Label required>Category</Label>
          <Select
            value={selectedCategory}
            placeholder="Select Category"
            options={availableCategories}
            onChange={(option) => {
              setSelectedCategory(option)
            }}
            isDisabled={loading}
          />
        </div>
        <div>
          <Label required>Selected Brand</Label>
          <Select
            value={selectedBrand}
            placeholder="Select Brand"
            options={availableBrands}
            onChange={(option) => {
              setSelectedBrand(option)
            }}
            isDisabled={!selectedCategory}
          />
        </div>
        <div className="empty-input-slot" />
        <div className="empty-input-slot" />
        <Action>
          <Button appearance="outline" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button onClick={submitBrand} disabled={!selectedBrand}>
            Save
          </Button>
        </Action>
      </Form>
    </Content>
  )
}
