/* eslint camelcase: "off" */

/** * Colors ***/
// Really Base Color
const base_color1 = '#000'
const base_color2 = '#fff'

// Main Colors
const primary = '#6324c6'
const primary_light2 = '#9B6CE4'
const primary_light1 = '#7C42DC'
const primary_dark1 = '#431985'
const primary_dark2 = '#160A2E'

const highlight = '#ffb800'
const highlight_light2 = '#FFB801'
const highlight_light1 = '#FFC633'
const highlight_dark1 = '#B38001'
const highlight_dark2 = '#4D3700'

// Default Background
const background = '#f5f7fb'

// Gray Scale
const gray = '#a7b0be'
const gray_light2 = '#E2E5E9'
const gray_light1 = '#C4CAD4'
const gray_dark1 = '#7B899E'
const gray_dark2 = '#4C5667'
const gray_new = '#F1F2FA'

// Feedback
const success = '#01ada6'
const warning = '#ffb800'
const danger = '#e92151'

// Shadows
const shadow_light = '3px 3px 12px rgba(0, 0, 0, 0.08), 0 0 6px 1px rgba(32, 32, 32, 0.02)'
const shadow_popup = '3px 3px 8px rgba(0, 0, 0, 0.16)'

/** * Export  ***/
export const COLORS = {
  base_color1,
  base_color2,
  primary,
  primary_light2,
  primary_light1,
  primary_dark1,
  primary_dark2,
  highlight,
  highlight_light2,
  highlight_light1,
  highlight_dark1,
  highlight_dark2,
  background,
  gray,
  gray_light2,
  gray_light1,
  gray_dark1,
  gray_dark2,
  gray_new,
  success,
  warning,
  danger,
  shadow_light,
  shadow_popup,
}
