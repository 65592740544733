import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Row = styled.tr`
  ${({ loading }) => {
    return loading
      ? `
      box-shadow: 0 0 0 1px ${COLORS.warning};
    `
      : ''
  }}
  height: 64px;
  grid-template-columns: ${({ columns }) => columns};
  background: ${COLORS.base_color2};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 5px;
  transition: box-shadow 200ms;
  &:first-child {
    height: 48px;
  }

  ${({ empty }) =>
    !empty &&
    `&:not(:first-child):hover {
      box-shadow: ${COLORS.shadow_light};
      cursor: pointer;
    }`}
`

export const Header = styled.th`
  font-weight: bold;
  font-size: 12px;
  color: ${COLORS.gray};
  padding: 0;

  &.active {
    color: ${COLORS.primary};
  }
`

export const Sort = styled(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ active }) => (active ? COLORS.primary : COLORS.gray)};
  transition: color 200ms;

  &:hover {
    ${({ active }) => (!active ? `color: ${COLORS.gray_dark1}` : '')};
  }

  svg {
    ${({ active }) => !active && 'width: 0;'}
    ${({ sort }) => sort === 'desc' && 'transform: rotate(180deg);'}
    margin-top: 2px;
    transition: width 200ms, transform 200ms;
  }
`
