import React from 'react'
import { Wrapper } from './styled'
import Select from '../Select'

const Filters = ({ filters = { width: null, selects: [] } }) => (
  <Wrapper width={filters.width}>
    {filters.selects.map(filter => (
      <Select key={JSON.stringify(filter)} {...filter} />
    ))}
  </Wrapper>
)

export default Filters
