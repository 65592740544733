import styled from 'styled-components'

export const FiltersWrapper = styled.form`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  justify-content: flex-start;
  color: black;
  column-gap: 14px;

  button {
    margin-left: auto;
  }
`

FiltersWrapper.Section = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 10px;
`
