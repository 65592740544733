import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Loading, Button } from '@winnin/insights_ui'

import Filters from '../../components/Filters'
import Page from '../../components/Page'
import UserModal from '../../components/UserModal'
import TablePagination from '../../components/TablePagination'
import UsersTable from '../../components/UsersTable'
import Error from '../../components/Error'
import Header from '../../components/Header'
import { USERS_QUERY, FILTER_OPTIONS_MUTATION } from './query'
import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTS } from '../../consts/pagination'
import sortByLabel from '../../utils/sortByLabel'
import Input from '../../components/Input'
import { FiltersWrapper } from './styled'

const UsersPage = () => {
  // state
  const [sortField, setSortField] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({
    companyIds: [],
    workspaceIds: [],
  })
  const [modalUser, setModalUser] = useState(null)
  const [initialLoading, setInitialLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(
    TABLE_PAGINATION_ROWS_PER_PAGE_OPTS[0]
  )
  const [userSearch, setUserSearch] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [activeUserSearch, setActiveUserSearch] = useState(false)
  const now = useRef(new Date())

  // queries and mutations
  const {
    loading: usersLoading,
    data: usersData,
    error: usersError,
    refetch: usersRefetch,
    fetchMore: usersFetchMore,
  } = useQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      timestamp: now.current,
      limit: rowsPerPage.value,
      offset: 0,
      ...filters,
      sort: {
        field: 'name',
        order: 'asc',
      },
    },
    onCompleted: useCallback(() => {
      setInitialLoading(false)
    }, []),
    onError: useCallback((error) => {
      console.error('UsersPage @ useQuery >>>>>', { error })
      setInitialLoading(false)
    }, []),
  })

  const [
    getFilterOptions,
    {
      loading: filterOptionsLoading,
      data: filterOptionsData,
      error: filterOptionsError,
    },
  ] = useMutation(FILTER_OPTIONS_MUTATION, {
    onError: useCallback((error) => {
      console.error('UsersPage @ FILTER_OPTIONS_QUERY >>>>>', { error })
    }, []),
  })

  // effects
  useEffect(() => {
    getFilterOptions()
  }, [])

  useEffect(() => {
    loadMore()
  }, [page, rowsPerPage])

  // functions
  const handleSort = (key) => {
    if (sortField === key) {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'

      setSortOrder(newSortOrder)
      usersRefetch({
        sort: {
          field: key,
          order: newSortOrder,
        },
      })
    } else {
      setSortField(key)
      setSortOrder('asc')
      usersRefetch({
        sort: {
          field: key,
          order: 'asc',
        },
      })
    }
  }

  const handleFilter = (filter, id) => {
    setFilters((prev) => ({ ...prev, [filter]: !id ? [] : [id] }))
    usersRefetch({
      [filter]: !id ? [] : [id],
    })
  }

  const handleEdit = (user = null) => {
    setIsModalOpen(true)
    setModalUser(user)
  }

  // Filter handler
  const handleFilterSearch = (e) => {
    e?.preventDefault()

    const variables = {
      name: userSearch,
      ...(activeUserSearch && { isActive: activeUserSearch }),
      email: userEmail || undefined,
    }

    usersRefetch(variables)
  }

  const loadMore = async () => {
    if (usersLoading) return

    try {
      await usersFetchMore({
        variables: {
          ...filters,
          offset: rowsPerPage.value * page,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.users) return prev
          return {
            ...prev,
            users: { ...fetchMoreResult.users },
          }
        },
      })
    } catch (error) {
      console.error('UsersPage @ loadMore >>>>>>>>>>>', { error })
    }
  }

  // rendering
  if (usersError) {
    return <Error />
  }

  const label = { singular: 'User', plural: 'Users' }

  const headers = [
    { label: 'Id' },
    { label: 'Role' },
    {
      label: 'Name',
      sortable: true,
      onClick: () => handleSort('name'),
      active: sortField === 'name',
      sort: sortOrder,
    },
    {
      label: 'Type',
    },
    {
      label: 'Company',
    },
    {
      label: 'Panels',
    },
    { label: 'Last Access' },
  ]

  const filterObj = filterOptionsError
    ? undefined
    : {
        width: '234px',
        selects: [
          {
            placeholder: 'Select Company',
            options: filterOptionsData?.companies
              ?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))
              ?.sort(sortByLabel),
            onChange: (option) => handleFilter('companyIds', option?.value),
            isClearable: true,
          },
          {
            placeholder: 'Select Panel',
            options: filterOptionsData?.brands
              ?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))
              ?.sort(sortByLabel),
            onChange: (option) => handleFilter('workspaceIds', option?.value),
            isClearable: true,
          },
        ],
      }

  return (
    <Page maxWidth={849}>
      <Filters filters={filterObj} />
      {initialLoading || filterOptionsLoading || usersLoading ? (
        <Loading topMargin />
      ) : (
        <>
          <Header
            length={usersData?.users?.rows || ''}
            action={handleEdit}
            label={label}
          />
          <FiltersWrapper onSubmit={handleFilterSearch}>
            <FiltersWrapper.Section>
              <p>User name</p>
              <Input
                name="search"
                placeholder="User name"
                value={userSearch}
                onChange={(event) => {
                  setUserSearch(event.target.value)
                }}
              />
            </FiltersWrapper.Section>
            <FiltersWrapper.Section>
              <p>User email</p>
              <Input
                name="email"
                placeholder="User email"
                value={userEmail}
                onChange={(event) => {
                  setUserEmail(event.target.value)
                }}
              />
            </FiltersWrapper.Section>
            <FiltersWrapper.Section>
              <p>Active</p>
              <input
                type="checkbox"
                checked={activeUserSearch}
                onChange={() => {
                  setActiveUserSearch((oldState) => !oldState)
                }}
              />
            </FiltersWrapper.Section>
            <Button type="submit">Filter</Button>
          </FiltersWrapper>
          <UsersTable
            columns="107px 107px 251px 107px 107px 170px 107px"
            headers={headers}
            data={usersData?.users?.entities}
            handleEdit={handleEdit}
            loading={usersLoading}
          />
          <TablePagination
            count={usersData?.users?.rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(newPage) => setPage(newPage)}
            onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          />
          <UserModal
            onRequestClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            user={modalUser}
            onCreateOrEdit={usersRefetch}
          />
        </>
      )}
    </Page>
  )
}

export default UsersPage
