import React from 'react'
import { Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { Loading } from '@winnin/insights_ui'

const LoginPage = ({ location }) => {
  const { keycloak } = useKeycloak()
  const from = location?.state?.from || { from: { pathname: '/' } }

  if (!keycloak.authenticated) {
    keycloak.login({
      redirectUri: `${window?.location?.origin}${from?.pathname}`,
    })
    return <Loading fullpage />
  }

  return <Redirect to={from} />
}

export default LoginPage
