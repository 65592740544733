// Sort function
export default (objectKey, order) => {
  return (a, b) => {
    let varA, varB

    switch (objectKey) {
      case 'name':
      case 'companyId':
      case 'country':
        varA = a[objectKey]?.toUpperCase() || ''
        varB = b[objectKey]?.toUpperCase() || ''
        break

      case 'company':
        varA = a[objectKey]?.name.toUpperCase() || ''
        varB = b[objectKey]?.name.toUpperCase() || ''
        break

      case 'brands':
        varA = a[objectKey][0]?.name.toUpperCase() || ''
        varB = b[objectKey][0]?.name.toUpperCase() || ''
        break

      case 'status':
      case 'role':
        varA = a[objectKey] ? 'Active' : 'Inactive'
        varB = b[objectKey] ? 'Active' : 'Inactive'
        break

      default:
        break
    }

    if (varA === '') return 1
    if (varB === '') return -1

    const comparison = varA.localeCompare(varB)
    return order === 'desc' ? comparison * -1 : comparison
  }
}
