import { gql } from 'apollo-boost'

export const USERS_QUERY = gql`
  query USERS_QUERY(
    $limit: Int = 100
    $offset: Int = 0
    $sort: SearchSort = { field: "name", order: "asc" }
    $companyIds: [String!] = []
    $workspaceIds: [String!] = []
    $timestamp: String
    $name: String
    $isActive: Boolean
    $email: String
  ) {
    users(
      limit: $limit
      offset: $offset
      sort: $sort
      companyIds: $companyIds
      workspaceIds: $workspaceIds
      timestamp: $timestamp
      name: $name
      email: $email
      isActive: $isActive
    ) {
      entities {
        company {
          registeredUsers
          companyId
          createdAt
          name
          slug
          companyType {
            id
            name
          }
        }
        email
        exportLimits {
          availableExports
          export {
            id
            name
            slug
          }
          exportsUsed
          maxExports
        }
        id
        name
        onboarding
        dashboardIds
        phone
        role
        thumbnail
        brands {
          id
          name
          thumbnail
          companyId
          panels {
            id
            title
            brandId
          }
        }
        firstName
        lastName
        country
        jobTitleType {
          id
          name
        }
        status
      }
      rows
    }
  }
`

export const FILTER_OPTIONS_MUTATION = gql`
  mutation usersPage_filterOptionsMutation {
    brands {
      id
      name
    }

    companies {
      id
      name
    }
  }
`
