import React, { useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Button } from '@winnin/insights_ui'
import copy from 'clipboard-copy'
import getLanguage from '../../utils/getLanguage'
import getCountry from '../../utils/getCountry'
import TableHeader from '../TableHeader'
import { useToastStore } from '../../stores/ToastStore'
import { Data, Image, Row, Status, List } from './styled'
import Tooltip from '../Tooltip'

dayjs.extend(utc)

const CompaniesTable = ({
  columns,
  headers,
  data,
  handleEdit,
  editingCompany,
}) => {
  const [activeTooltip, setActiveTooltip] = useState(null)
  const { dispatch } = useToastStore()

  const isTooltipVisible = (id) => {
    return id === activeTooltip
  }

  return (
    <table>
      <TableHeader columns={columns} headers={headers} />

      {data.map((row) => (
        <Row
          columns={columns}
          key={row.id}
          onClick={() => handleEdit(row)}
          loading={row.id === editingCompany.id}
        >
          <Status
            active={row.status}
            onMouseEnter={() => setActiveTooltip(`${row.id}-status`)}
            onMouseLeave={() => setActiveTooltip(null)}
          >
            <Tooltip visible={isTooltipVisible(`${row.id}-status`)}>
              {row.status ? 'Active' : 'Inactive'}
            </Tooltip>
          </Status>

          {row.name.length > 10 ? (
            <Image
              thumbnail={row.thumbnail}
              onMouseEnter={() => setActiveTooltip(`${row.id}-name`)}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              <div className="company-thumbnail" />
              <span>
                {row.name.slice(0, 10)}...
                <Tooltip visible={isTooltipVisible(`${row.id}-name`)}>
                  <p>{row.name}</p>
                </Tooltip>
              </span>
            </Image>
          ) : (
            <Image thumbnail={row.thumbnail}>
              <div className="company-thumbnail" />
              <span>{row.name}</span>
            </Image>
          )}

          {row.country && getCountry(row.country)?.length > 10 ? (
            <Data
              onMouseEnter={() => setActiveTooltip(`${row.id}-country`)}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              {`${getCountry(row.country).slice(0, 10)}...`}
              <Tooltip visible={isTooltipVisible(`${row.id}-country`)}>
                <p>{getCountry(row.country)}</p>
              </Tooltip>
            </Data>
          ) : (
            <Data>{row.country ? getCountry(row.country) : '-'}</Data>
          )}

          <Data>
            {row?.workspacesCount ?? '-'}/
            {row?.companyPlan?.workspaces ?? row?.plan?.workspaces ?? '-'}
          </Data>
          <Data>
            {row?.usersCount?.viewers ?? '-'}/
            {row?.companyPlan?.viewers ?? row?.plan?.viewers ?? '-'}
          </Data>
          <Data>
            {row?.usersCount?.editors ?? '-'}/
            {row?.companyPlan?.editors ?? row?.plan?.editors ?? '-'}
          </Data>
          <Data>{dayjs(row?.companyPlan?.startDate).utc().format('DD/MM/YYYY')}</Data>
          <Data>{dayjs(row?.companyPlan?.endDate).utc().format('DD/MM/YYYY')}</Data>
          {row?.companyPlan?.languages?.length > 2 ? (
            <Data>
              <List
                onMouseEnter={() => setActiveTooltip(`${row.id}-language`)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <li>{getLanguage(row?.companyPlan?.languages[0])}</li>
                <li>{getLanguage(row?.companyPlan?.languages[1])}...</li>

                <Tooltip visible={isTooltipVisible(`${row.id}-language`)}>
                  <ul>
                    {row?.companyPlan?.languages.slice(2).map((lang, index) => (
                      <li key={index}>{getLanguage(lang)}</li>
                    ))}
                  </ul>
                </Tooltip>
              </List>
            </Data>
          ) : (
            <Data>
              {row?.companyPlan?.languages?.length ? (
                <List>
                  {row?.companyPlan?.languages.map((language, index) => (
                    <li key={index}>{getLanguage(language)}</li>
                  ))}
                </List>
              ) : (
                '-'
              )}
            </Data>
          )}
          <div>
            <Button
              appearance="outline"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                copy(row.id)
                dispatch({
                  type: 'create',
                  message: 'Company ID copied to clipboard',
                })
              }}
            >
              Copy ID
            </Button>
          </div>
        </Row>
      ))}
    </table>
  )
}

export default CompaniesTable
