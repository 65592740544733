export default str => {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaaaeeeeiiiioooouuuunc------'

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')

  return str
}
