import React from 'react'
import Illustration from '../../assets/Error.svg'
import { Wrapper, Title, Text } from './styled'

const Error = () => (
  <Wrapper>
    <div>
      <Title>
        Oops,
        <br />
        something went wrong!
      </Title>
      <Text>Please refresh the page or try again later</Text>
    </div>
    <img src={Illustration} alt="404 - Page not Found" />
  </Wrapper>
)

export default Error
