import React, { useState, useEffect } from 'react'
import { TabButton, TabsWrapper } from './styled'

export default function ModalTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(null)

  const tabsHeaders = Object.keys(tabs)

  const changeTab = (tab) => {
    return () => {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    setActiveTab(tabsHeaders[0])
  }, [])

  return (
    <div>
      <TabsWrapper>
        {tabsHeaders.map((tab) => (
          <TabButton
            type="button"
            onClick={changeTab(tab)}
            isActive={tab === activeTab}
          >
            {tab}
          </TabButton>
        ))}
      </TabsWrapper>
      {tabs[activeTab]}
    </div>
  )
}
