import React from 'react'
import { Icon } from '@winnin/insights_ui'
import { COLORS } from '../../theme/colors'
import { Row, Header, Sort } from './styled'

const TableHeader = ({ columns, headers }) => (
  <Row columns={columns}>
    {headers.map((header) =>
      header.sortable ? (
        <Sort
          active={header.active}
          sort={header.sort}
          onClick={header.onClick}
        >
          <Icon icon="arrow" fill={COLORS.primary} />
          <span>{header.label}</span>
        </Sort>
      ) : (
        <Header>{header.label}</Header>
      )
    )}
  </Row>
)

export default TableHeader
