import React, { createContext, useContext, useReducer } from 'react'

const StoreContext = createContext()
const initialState = { message: null }

const reducer = (state, action) => {
  switch (action.type) {
    case 'create':
      return {
        message: action.message,
      }

    case 'delete':
      return {
        message: null,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ToastStoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useToastStore = () => useContext(StoreContext)
