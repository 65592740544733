import React from 'react'
import ReactSelect from 'react-select'
import { COLORS } from '../../theme/colors'

const customStyles = {
  option: (provided, { isDisabled, isFocused, isSelected }) => ({
    ...provided,
    color: isSelected
      ? COLORS.base_color2
      : isFocused
        ? COLORS.base_color2
        : isDisabled
          ? COLORS.gray_light1
          : COLORS.gray_dark2,
    backgroundColor: isSelected
      ? COLORS.primary
      : isFocused
        ? COLORS.primary_light2
        : COLORS.base_color2,
    cursor: isDisabled ? 'not-allowed' : 'auto',
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${state.isFocused ? COLORS.primary : COLORS.gray}`,
    },
    border: state.isFocused ? `1px solid ${COLORS.primary}` : '',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 3,
  }),
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, paddingRight: 6 } : base
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base
  },
}

const Select = props => {
  return <ReactSelect styles={customStyles} {...props} />
}

export default Select
