import styled from 'styled-components'
import Select from '../Select'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ leftSpacing }) => leftSpacing || '33%'} 1fr;
  height: 64px;
  background-color: ${COLORS.base_color2};
`

export const SmallSelect = styled(Select)`
  width: 84px;
`

export const TablePaginationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
`

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #17092f;
`
export const ArrowWrapper = styled.div`
  display: flex;

  svg {
    cursor: pointer;
    margin: 0 12px;
  }
`
