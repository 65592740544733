import Keycloak from './keycloak'
import getEnvFromLocation from './getEnvFromLocation'
import { REALMS, CLIENT_IDS, ENDPOINT } from '../consts/keycloak'

const env = getEnvFromLocation()

export const keycloak = new Keycloak({
  url: ENDPOINT[env],
  realm: REALMS[env],
  clientId: CLIENT_IDS[env],
})

export const keycloakProviderProps = {
  authClient: keycloak,
  initConfig: { onLoad: 'check-sso', enableLogging: true },
}
