import React, { useState, useRef, useEffect } from 'react'
import { Loading, Button } from '@winnin/insights_ui'
import dayjs from 'dayjs'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import Page from '../../components/Page'
import TablePagination from '../../components/TablePagination'
import CompanyModal from '../../components/CompanyModal'
import CompaniesTable from '../../components/CompaniesTable'
import Error from '../../components/Error'
import Header from '../../components/Header'
import {
  COMPANIES_QUERY,
  COMPANY_TYPES_QUERY,
  COMPANY_SIZES_QUERY,
  LIST_CONTRACTS,
} from './query'
import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTS } from '../../consts/pagination'
import compareValues from '../../utils/compareValues'
import Input from '../../components/Input'
import { FiltersWrapper } from './styled'

const DEFAULT_FILTERS = {
  search: '',
  active: true,
}

const now = dayjs().unix()

const CompaniesPage = () => {
  // State
  const [activeSort, setActiveSort] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortedList, setSortedList] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    TABLE_PAGINATION_ROWS_PER_PAGE_OPTS[0]
  )
  const [openModal, setOpenModal] = useState(false)
  const [modalCompany, setModalCompany] = useState(null)
  const [editingCompany, setEditingCompany] = useState({})
  const [error, setError] = useState(false)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const nowString = useRef(new Date())

  // Data used by the Table component
  const columns = '84px 170px 107px 84px 107px 84px 107px 107px 107px 97px'
  const headers = [
    { label: 'Status' },
    {
      label: 'Company',
      sortable: true,
      onClick: () => handleSort('name'),
      active: activeSort === 'name',
      sort: sortOrder,
    },
    {
      label: 'Country',
      sortable: true,
      onClick: () => handleSort('country'),
      active: activeSort === 'country',
      sort: sortOrder,
    },
    { label: 'Worspaces' },
    { label: 'Viewers' },
    { label: 'Editors' },
    { label: 'Start Date' },
    { label: 'End Date' },
    { label: 'Language' },
    { label: 'Company Id' },
  ]

  const label = { singular: 'Company', plural: 'Companies' }

  const [loadCompanies, companies] = useLazyQuery(COMPANIES_QUERY, {
    fetchpolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      timestamp: now,
      offset: 0,
      limit: rowsPerPage.value,
    },
    onError: (error) => {
      setError(true)
      console.error(`CompanyPage @ COMPANY_QUERY >>>>> ${error}`)
    },
  })

  useEffect(() => {
    loadCompanies()
  }, [])

  useEffect(() => {
    if (companies.data?.companies2?.rows) {
      setSortedList(companies.data?.companies2.entities)
    }
  }, [companies])

  const { data: companyTypes, loading: loadingCompanyTypes } = useQuery(
    COMPANY_TYPES_QUERY,
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        setError(true)
        console.error(`CompanyPage @ COMPANY_TYPES_QUERY >>>>> ${error}`)
      },
    }
  )

  const { data: companySizes, loading: loadingCompanySizes } = useQuery(
    COMPANY_SIZES_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        data?.listCompanySizes?.entities?.length > 0
          ? (data.listCompanySizes = data.listCompanySizes.entities)
          : (data.listCompanySizes = [])
      },
      onError: (error) => {
        setError(true)
        console.error(`CompanyPage @ COMPANY_SIZES_QUERY >>>>> ${error}`)
      },
    }
  )

  const { data: contractsData, loading: loadingContracts } = useQuery(
    LIST_CONTRACTS,
    {
      variables: { timestamp: nowString.current },
      fetchPolicy: 'network-only',
      onError: (error) => {
        setError(true)
        console.error(`CompanyPage @ LIST_CONTRACTS_QUERY  >>>>> ${error}`)
      },
    }
  )

  useEffect(() => {
    loadMore()
  }, [page])

  const handleCompanyRefetch = (company = {}) => {
    setEditingCompany(company)
    loadCompanies()
  }

  useEffect(() => {
    if (
      !companies.loading ||
      !loadingCompanyTypes ||
      !loadingCompanySizes ||
      !loadingContracts
    )
      setEditingCompany({})
  }, [
    companies.loading,
    loadingCompanyTypes,
    loadingCompanySizes,
    loadingContracts,
  ])

  // "Did update"
  useEffect(() => {
    if (companies?.data?.companies2?.entities) {
      const currentSortList = companies?.data?.companies2?.entities.sort(
        compareValues('name', 'asc')
      )
      const inactivesLast = currentSortList.sort(compareValues('status', 'asc'))
      setSortedList(inactivesLast)
    }
  }, [companies?.data])

  // Column sort handler
  const handleSort = (key) => {
    const reversedSorteOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    const sort = activeSort === key ? reversedSorteOrder : 'asc'
    const nameSortList = sortedList.sort(compareValues('name', 'asc'))
    const currentSortList = nameSortList.sort(compareValues(key, sort))
    const inactivesLast = currentSortList.sort(compareValues('status', 'asc'))

    setActiveSort(key)
    setSortOrder(sort)
    setSortedList(inactivesLast)
  }

  // Modal handler
  const handleModal = () => {
    setOpenModal(!openModal)
  }

  // Company handler
  const handleEdit = (company = null) => {
    setOpenModal(true)
    setModalCompany(company)
  }

  // Filter handler
  const handleFilter = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleFilterSearch = (e) => {
    e.preventDefault()
    const variables = {
      timestamp: now,
      ...(filters.active && { status: filters.active }),
      name: filters.search,
    }
    loadCompanies({ variables })
  }

  const loadMore = () => {
    if (companies.loading) return

    try {
      loadCompanies({
        variables: {
          offset: rowsPerPage.value * page,
          ...(filters.active && { status: filters.active }),
          ...(filters.search && { name: filters.search }),
        },
      })
    } catch (error) {
      console.error('CompaniesPage @ loadMore >>>>>>>>>>>', { error })
    }
  }

  if (error) {
    return <Error />
  }

  if (
    companies.loading ||
    loadingCompanyTypes ||
    loadingCompanySizes ||
    loadingContracts
  )
    return <Loading topMargin />

  return (
    <Page maxWidth={1081}>
      <Header
        length={companies?.data?.companies2?.rows}
        action={handleEdit}
        label={label}
      />
      <FiltersWrapper onSubmit={handleFilterSearch}>
        <FiltersWrapper.Section>
          <p>Company</p>
          <Input
            name="search"
            placeholder="Company name"
            value={filters.search}
            onChange={(event) => {
              handleFilter('search', event.target.value)
            }}
          />
        </FiltersWrapper.Section>
        <FiltersWrapper.Section>
          <p>Active</p>
          <input
            type="checkbox"
            checked={filters.active}
            onChange={() => {
              handleFilter('active', !filters.active)
            }}
          />
        </FiltersWrapper.Section>
        <Button type="submit">Filter</Button>
      </FiltersWrapper>
      <CompaniesTable
        columns={columns}
        headers={headers}
        data={sortedList}
        handleEdit={handleEdit}
        editingCompany={editingCompany}
      />
      <TablePagination
        count={companies?.data?.companies2?.rows}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(newPage) => setPage(newPage)}
        onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
      />
      <CompanyModal
        onRequestClose={handleModal}
        isOpen={openModal}
        company={modalCompany}
        onCreateOrEdit={handleCompanyRefetch}
        companyTypes={companyTypes}
        companySizes={companySizes}
        contractsData={contractsData}
      />
    </Page>
  )
}

export default CompaniesPage
