import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 38px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 31px;

  & > div {
    width: ${({ width }) => width};

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`
