import styled from 'styled-components'
import { Button } from '@winnin/insights_ui'
import { NavLink } from 'react-router-dom'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  background: ${COLORS.base_color2};
  padding: 34px 19px;
`

export const Sticky = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 2 * 34px);
  position: sticky;
  top: 34px;
`

export const LogoWrapper = styled.div`
  fill: ${COLORS.gray};
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  color: ${COLORS.gray_dark2};

  svg {
    fill: ${COLORS.gray_dark2};
  }

  .pipe {
    background: ${COLORS.gray_dark2};
    margin: 0 10px;
    height: 20px;
    width: 1px;
    display: block;
    position: relative;
    top: 3px;
  }

  .text {
    display: inline-block;
    font-size: 24px;
    line-height: 1;

    small {
      vertical-align: super;
      font-size: 12px;
    }
  }
`

export const Title = styled.h1`
  margin-top: 48px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`

export const Navigation = styled.div`
  margin-top: 61px;
  display: inline-flex;
  flex-direction: column;
`

export const Link = styled(NavLink)`
  color: ${COLORS.gray};
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  transition: color 200ms;

  &:not(:first-child) {
    margin-top: 37px;
  }

  &:hover {
    color: ${COLORS.gray_dark1};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 3px;
    background: ${COLORS.primary};
    top: calc(100% + 3px);
    transition: width 200ms;
  }

  &.active {
    color: ${COLORS.primary};
  }

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    width: 50px;
    height: 3px;
    background: ${COLORS.primary};
    top: calc(100% + 3px);
  }
`

export const LogoutButton = styled(Button)`
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50px;
  font-weight: bold;
`
