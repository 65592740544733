import { gql } from 'apollo-boost'

export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION(
    $firstName: String!
    $lastName: String!
    $name: String!
    $email: String!
    $role: Int = 100
    $companyId: String!
    $phone: String
    $brandIds: [String!]
    $country: String!
    $jobTitleId: Int!
    $status: Boolean = true
    $sendToCRM: Boolean = true
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      name: $name
      email: $email
      role: $role
      companyId: $companyId
      phone: $phone
      brandIds: $brandIds
      country: $country
      jobTitleId: $jobTitleId
      status: $status
      sendToCRM: $sendToCRM
    ) {
      success
      message
    }
  }
`

export const EDIT_USER_MUTATION = gql`
  mutation EDIT_USER_MUTATION(
    $firstName: String!
    $lastName: String!
    $name: String!
    $email: String!
    $role: Int = 100
    $companyId: String!
    $phone: String
    $brandIds: [String!]
    $country: String!
    $jobTitleId: Int!
    $status: Boolean = true
    $userId: String!
  ) {
    editUser(
      firstName: $firstName
      lastName: $lastName
      name: $name
      email: $email
      role: $role
      companyId: $companyId
      phone: $phone
      brandIds: $brandIds
      country: $country
      jobTitleId: $jobTitleId
      status: $status
      userId: $userId
    ) {
      success
      message
    }
  }
`

export const QUERY_BRANDS_COMPANIES = gql`
  mutation QUERY_BRANDS_COMPANIES {
    brands {
      id
      name
      companyId
    }
  }
`

export const QUERY_COMPANIES = gql`
  query COMPANIES_QUERY {
    companies2 {
      entities {
        ... on Company {
          companyId
          name
          usersCount {
            editors
            viewers
          }
          plan {
            editors
            viewers
          }
          companyPlan {
            editors
            viewers
          }
          companyType {
            id
          }
        }
      }
    }
  }
`

export const JOB_TITLES_QUERY = gql`
  query userModal_jobTitlesQuery($companyTypeId: Int!) {
    jobTitles(companyTypeId: $companyTypeId) {
      id
      name
    }
  }
`

export const SEND_WELCOME_EMAIL = gql`
  query SEND_WELCOME_EMAIL($userId: String!) {
    sendWelcomeEmail(userId: $userId) {
      success
      message
      __typename
    }
  }
`
