import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  > div {
    margin: 0 5px;
  }
`

export const Switch = styled.div`
  width: 36px;
  height: 21px;
  border-radius: 999px;
  position: relative;
  transition: 200ms;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? COLORS.primary : COLORS.gray_light1};

  &::after {
    content: '';
    background-color: ${COLORS.base_color2};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    transition: 200ms;
    top: 3px;
    left: ${({ active }) => (active ? '18px' : '3px')};
  }
`

export const Label = styled.span`
  font-size: 12px;
  color: ${COLORS.gray_dark2};
  font-weight: bold;
  cursor: pointer;
`
