import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Button } from '@winnin/insights_ui'
import Page from '../../components/Page'
import Input, { Label } from '../../components/Input'
import { ADD_CREATORS, ADD_VIDEOS } from './query'
import { ContentWrapper, PageWrapper, Wrapper } from './styled'
import { useToastStore } from '../../stores/ToastStore'

export default function AddVideosCreators() {
  const { dispatch } = useToastStore()
  const [videoUrl, setVideoUrl] = React.useState('')
  const [creatorUrl, setCreatorUrl] = React.useState('')

  const [fetchPostSync, { loading: videoLoading }] = useMutation(ADD_VIDEOS, {
    onError: (error) => {
      console.error('AddVideosCreators @ ADD_VIDEOS >>>>>', { error })

      dispatch({
        type: 'create',
        message: 'An error occurred. Please, try again.',
      })
    },
    onCompleted: (data) => {
      if (data.fetchPostSync.__typename === 'ScrapedPostBasic') {
        setVideoUrl('')

        dispatch({
          type: 'create',
          message: 'Video added successfully',
        })
      }
      else {
        dispatch({
          type: 'create',
          message: `Request failed. Please try again. Reason code: ${data.fetchPostSync.code} Reason message: ${data.fetchPostSync.message}`,
        })
      }
    },
  })

  const [fetchCreatorSync, { loading: creatorLoading }] = useMutation(
    ADD_CREATORS,
    {
      onError: (error) => {
        console.error('AddVideosCreators @ ADD_CREATORS >>>>>', { error })

        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
      },
      onCompleted: (data) => {
        if (data.fetchCreatorSync.__typename === 'ScrapedCreatorBasic') {
          setCreatorUrl('')

          dispatch({
            type: 'create',
            message: 'Creator added successfully',
          })
        }
        else {
          dispatch({
            type: 'create',
            message: `Request failed. Please try again. Reason code: ${data.fetchCreatorSync.code} Reason message: ${data.fetchCreatorSync.message}`,
          })
        }
      },
    }
  )

  const handleVideoSubmit = () => {
    if (!videoUrl) return
    fetchPostSync({ variables: { data: { url: videoUrl } } })
  }

  const handleCreatorSubmit = () => {
    if (!creatorUrl) return
    fetchCreatorSync({ variables: { data: { url: creatorUrl } } })
  }

  return (
    <Page maxWidth={849}>
      <PageWrapper>
        <ContentWrapper>
          <Label>Video url</Label>
          <Wrapper>
            <Input
              name="video"
              placeholder="Add video url"
              value={videoUrl}
              onChange={(event) => {
                setVideoUrl(event.target.value)
              }}
            />
            <Button
              type="submit"
              onClick={handleVideoSubmit}
              disabled={videoLoading || !videoUrl}
            >
              {videoLoading ? '...Loading' : 'Submit'}
            </Button>
          </Wrapper>
        </ContentWrapper>

        <ContentWrapper>
          <Label>Creator url</Label>
          <Wrapper>
            <Input
              name="creator"
              placeholder="Add creator url"
              value={creatorUrl}
              onChange={(event) => {
                setCreatorUrl(event.target.value)
              }}
            />
            <Button
              type="submit"
              onClick={handleCreatorSubmit}
              disabled={creatorLoading || !creatorUrl}
            >
              {creatorLoading ? '...Loading' : 'Submit'}
            </Button>
          </Wrapper>
        </ContentWrapper>
      </PageWrapper>
    </Page>
  )
}
