import React, { Component } from 'react'
import Error from '../Error'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error) {
    console.error('ErrorBoundary -> componentDidCatch -> error', error)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return <Error />
    }

    return this.props.children
  }
}

export default ErrorBoundary
