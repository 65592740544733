import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { Loading } from '@winnin/insights_ui'
import AppHeader from './components/AppHeader'
import Toast from './components/Toast'
import LoginPage from './pages/LoginPage'
import { AppWrapper } from './styled'
import { GlobalStyle } from './theme'
import { ToastStoreProvider } from './stores/ToastStore'
import Router from './Router'
import { startMixpanel } from './utils/mixpanel'

const App = () => {
  const { initialized } = useKeycloak()

  useEffect(() => {
    startMixpanel()
  }, [])

  if (!initialized) return <Loading fullpage />
  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/companies" />
        </Route>
        <Route path="/login" component={LoginPage} />
        <AppWrapper>
          <AppHeader />
          <ToastStoreProvider>
            <Router />
            <Toast />
          </ToastStoreProvider>
        </AppWrapper>
      </Switch>
    </>
  )
}

export default App
