import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: ${COLORS.shadow_light};
  background-color: ${COLORS.primary};
  color: ${COLORS.base_color2};
  position: fixed;
  z-index: 999;
  left: 360px;
  bottom: 40px;
  transition: 200ms;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  p {
    font-size: 12px;
  }
`
