/* eslint-disable import/first */
if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { BrowserRouter as Router } from 'react-router-dom'
import { keycloakProviderProps } from './utils/auth'
import App from './App'
import client from './ApolloClient'
import 'regenerator-runtime/runtime'
import '@winnin/insights_ui/dist/lib/insights_ui_theme.css'

ReactDOM.render(
  <ReactKeycloakProvider {...keycloakProviderProps}>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </ReactKeycloakProvider>,
  document.getElementById('root')
)
