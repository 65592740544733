export default [
  {
    label: 'Inactive',
    value: 0,
  },
  {
    label: 'Viewer',
    value: 50,
  },
  {
    label: 'Editor',
    value: 100,
  },
  {
    label: 'Company Admin',
    value: 150,
  },
]
