import React from 'react'
import { Wrapper, Title, Action } from './styled'

const Header = ({ length, action, label }) => (
  <Wrapper>
    <Title>{`${length} ${length !== 1 ? label.plural : label.singular}`}</Title>
    <Action icon="add" onClick={() => action()}>
      Add {label.singular}
    </Action>
  </Wrapper>
)

export default Header
