import { css } from 'styled-components'
import { COLORS } from './colors'

export const TYPOGRAPHY = css`
  // General Text
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0px;
    font-family: 'Muli', Arial, sans-serif;

    &.light {
      font-weight: 300;
    }
    &.regular {
      font-weight: 400;
    }
    &.semi {
      font-weight: 600;
    }
    &.bold {
      font-weight: 700;
    }
  }

  // Headers
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${COLORS.gray_dark2};
  }

  p,
  .p {
    font-size: 1rem;
  }

  h1,
  .h1 {
    font-size: 3rem;
  }
  h2,
  .h2 {
    font-size: 2.25rem;
  }
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
  }
  h6,
  .h6 {
    font-size: 0.875rem;
  }
`
