export function getCreatorURL({ provider, username, creatorId }) {
  switch (provider) {
    case 'yt':
      return `https://www.youtube.com/channel/${creatorId || username}`
    case 'in':
      return `https://www.instagram.com/${username}`
    case 'fb':
      return `https://www.facebook.com/${creatorId}`
    case 'tw':
      return `https://www.twitch.tv/${username}`
    case 'tt':
      return `https://www.tiktok.com/@${username}`
    default:
      break
  }
}
