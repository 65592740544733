import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Logo } from './logo'
import {
  Wrapper,
  Title,
  Navigation,
  Link,
  LogoutButton,
  Sticky,
} from './styled'

const AppHeader = () => {
  const { keycloak } = useKeycloak()

  const logout = () => {
    keycloak.logout()
  }

  return (
    <Wrapper>
      <Sticky>
        <Logo />

        <Title>Admin</Title>

        <Navigation>
          <Link activeClassName="active" to="/companies">
            Companies
          </Link>

          <Link activeClassName="active" to="/workspaces">
            Workspaces
          </Link>

          <Link activeClassName="active" to="/users">
            Users
          </Link>

          <Link activeClassName="active" to="/add-videos-creators">
            Add videos/creators
          </Link>

          {/* <Link activeClassName="active" to="/plans">
            Plans
          </Link> */}
        </Navigation>

        <LogoutButton appearance="link" onClick={logout}>
          Logout
        </LogoutButton>
      </Sticky>
    </Wrapper>
  )
}

export default AppHeader
