import { gql } from 'apollo-boost'

export const COMPANIES_QUERY = gql`
  query COMPANIES_QUERY(
    $limit: Int = 50
    $offset: Int = 0
    $timestamp: Int
    $status: Boolean
    $name: String
  ) {
    companies2(
      limit: $limit
      offset: $offset
      ts: $timestamp
      status: $status
      name: $name
    ) {
      entities {
        ... on Company {
          registeredUsers
          id
          contractEmail
          contractId
          durationInMonths
          hubspotCompanyId
          createdAt
          name
          slug
          brands
          brandExport
          topicExport
          country
          searchExport
          creatorExport
          cultureExport
          performanceExport
          status
          size {
            id
            name
          }
          thumbnail
          associatedBrands {
            id
            name
            creators {
              name
              title
              provider
              creatorId
              thumbnail
            }
          }
          usersCount {
            editors
            viewers
          }
          workspacesCount
          companyType {
            id
            name
          }
          plan {
            id
            name
            jobs
            hasWinninReports
            csTreatment
            editors
            viewers
            workspaces
            workspaceChannels
            providers
            languages
            isActive
            savedPostSearches
          }
          companyPlan {
            id: planId
            isActive
            jobs
            providers
            hasWinninReports
            csTreatment
            editors
            viewers
            workspaces
            workspaceChannels
            languages
            startDate
            endDate
            savedPostSearches
          }
        }
      }
      rows
    }
  }
`

export const LIST_CONTRACTS = gql`
  query LIST_CONTRACTS($timestamp: String) {
    contracts(timestamp: $timestamp) {
      entities {
        ... on Contract {
          id
          name
        }
      }
    }
  }
`

export const COMPANY_TYPES_QUERY = gql`
  query companyModal_companyTypesQuery {
    companyTypes {
      id
      name
    }
  }
`

export const COMPANY_SIZES_QUERY = gql`
  query companyModal_companySizesQuery {
    listCompanySizes(limit: 10, offset: 0) {
      entities {
        id
        name
      }
    }
  }
`
