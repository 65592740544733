import React, { useState } from 'react'
import { Button } from '@winnin/insights_ui'
import { useMutation } from '@apollo/react-hooks'

import {
  Content,
  Form,
  Input,
  Label,
  Title,
  DeleteButton,
  ListItem,
  UnorderedList,
  BrandCard,
  Image,
  SectionButtonWrapper,
  Subtitle,
} from '../../styled'
import {
  CREATE_COMPANY_ASSOCIATED_BRAND,
  DELETE_COMPANY_ASSOCIATED_BRAND,
} from './query'
import { useToastStore } from '../../../../stores/ToastStore'
import { getCreatorURL } from '../../../../utils/creator'

export default function AssociatedBrands({ company, onRequestClose }) {
  const [formState, setFormState] = useState(null)
  const { dispatch } = useToastStore()

  const [createAssociatedBrand, { loading: loadingCreate }] = useMutation(
    CREATE_COMPANY_ASSOCIATED_BRAND,
    {
      onCompleted: () => {
        const message = 'Brand Created.'
        dispatch({
          type: 'create',
          message,
        })
        onRequestClose()
      },
      onError: (error) => {
        console.error('AssociatedBrandsTab @ create >>>>>', error)

        if (error.message === 'GraphQL error: duplicated-value') {
          dispatch({
            type: 'create',
            message: 'Brand already exists.',
          })
          onRequestClose()
          return
        }

        dispatch({
          type: 'create',
          message: 'An error occurred. Please, try again.',
        })
        onRequestClose()
      },
      refetchQueries: ['COMPANIES_QUERY', 'WORKSPACES_QUERY'],
    }
  )

  const [deleteCompanyAssociatedBrand, { loading: loadingDelete }] =
    useMutation(DELETE_COMPANY_ASSOCIATED_BRAND, {
      onCompleted: ({ deleteCompanyAssociatedBrand }) => {
        if (deleteCompanyAssociatedBrand?.success) {
          const message = 'Brand Deleted.'
          dispatch({
            type: 'delete',
            message,
          })
          onRequestClose()
        }

        dispatch({
          type: 'delete',
          message: 'An error occurred. Please, try again.',
        })
        onRequestClose()
      },
      onError: (error) => {
        console.error('AssociatedBrandsTab @ delete >>>>>', error)
        dispatch({
          type: 'delete',
          message: 'An error occurred. Please, try again.',
        })
        onRequestClose()
      },
      refetchQueries: ['COMPANIES_QUERY', 'WORKSPACES_QUERY'],
    })

  const handleChange = (e) => {
    const { name, value, type } = e.target
    if (type === 'number') {
      setFormState({ ...formState, [name]: +value })
      return
    }
    setFormState({ ...formState, [name]: value })
  }

  const handleCreateBrand = async (e) => {
    await createAssociatedBrand({
      variables: {
        brandName: formState.name,
        creatorURLs: formState.creatorURL ? [formState.creatorURL] : [],
        companyId: company.id,
      },
    })

    setFormState(null)
  }

  const handleDeleteBrand = async (id) => {
    await deleteCompanyAssociatedBrand({
      variables: {
        id,
      },
    })
  }

  return (
    <Content>
      <Title>Brands</Title>
      <Subtitle>Brands associated to company</Subtitle>
      {company.associatedBrands?.length > 0 && (
        <UnorderedList>
          {company.associatedBrands.map((brand) => {
            const creator = brand.creators?.[0]

            return (
              <ListItem>
                <a
                  href={
                    creator
                      ? getCreatorURL({
                          provider: creator.provider,
                          creatorId: creator.creatorId,
                          username: creator.title || creator.name,
                        })
                      : '#'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <BrandCard>
                    <Image thumbnail={creator?.thumbnail} />
                    <Label noMargin>{brand.name}</Label>
                    <DeleteButton
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleDeleteBrand(brand.id)
                      }}
                      disabled={loadingDelete}
                    >
                      X
                    </DeleteButton>
                  </BrandCard>
                </a>
              </ListItem>
            )
          })}
        </UnorderedList>
      )}

      <Form onSubmit={(e) => e.preventDefault()}>
        <div>
          <Label required>Brand Name</Label>
          <Input
            name="name"
            value={formState?.name}
            onChange={handleChange}
            type="text"
            required
          />
        </div>
        <div>
          <Label>Creator URL</Label>
          <Input
            name="creatorURL"
            value={formState?.creatorURL}
            onChange={handleChange}
            type="url"
          />
        </div>
        {/* </DoubleInputWrapper> */}
        <SectionButtonWrapper>
          <Button
            appearance="outline"
            onClick={handleCreateBrand}
            disabled={
              loadingCreate ||
              !formState?.name ||
              formState.name.trim().length <= 1
            }
          >
            Create Brand
          </Button>
        </SectionButtonWrapper>
      </Form>
    </Content>
  )
}
